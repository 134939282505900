import { useContext } from 'react';
import { Select } from 'antd';


import Resource from 'models/resource';
import * as helpers from 'util/helpers';
import AuthContext from 'contexts/authContext';


const filterTypes = Resource.TYPES;
const { Option } = Select;


const SelectTypeInput = ({ value, handleDeselect, handleSelect }) => {
  const { profile } = useContext(AuthContext);

  // Only show resource types that the user is allowed to see
  const resourceTypes = profile && profile.permission ? profile.permission.getAllowedResourceTypes() : []
  const filterTypesEntries = Object.entries(filterTypes).filter(t => {
    return resourceTypes.includes(t[1])
  })

  return (
    <Select
      mode="multiple"
      style={{ width: '100%' }}
      placeholder="select a resource type"
      onDeselect={handleDeselect}
      onSelect={handleSelect}
      optionLabelProp="label"
      value={value}
      allowClear
    >
      {filterTypesEntries.map((filterTypeEntry, idx) => {
        return (
          <Option value={filterTypeEntry[0]} label={helpers.formatResourceTypeName(filterTypeEntry[1])} key={idx}>
            <div className="demo-option-label-item">
              {helpers.formatResourceTypeName(filterTypeEntry[1])}
            </div>
          </Option>
        )
      })}
    </Select>
  );  
}

export default SelectTypeInput;
