import { DEFAULT_PATHS } from 'config.js';
import { getPath, PATH_NAMES } from 'util/urls';
import { ROLES } from 'models/permission';

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;

const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: `${appRoot}/dashboard`,
      label: 'menu.dashboard',
      icon: 'grid-3',
      useExactPath: true,
      excludedRoles: []
    },
    {
      path: `${appRoot}${getPath(PATH_NAMES.resources).link}`,
      label: 'menu.dashboard.resources',
      icon: 'list',
      excludedRoles: []
    },
    // {
    //   path: `${appRoot}/dashboard/groups`,
    //   label: 'menu.dashboard.groups',
    //   icon: 'list',
    //   excludedRoles: []
    // },
    // {
    //   path: `${appRoot}/dashboard/search`,
    //   label: 'menu.dashboard.search',
    //   icon: 'grid-3',
    //   excludedRoles: []
    // },
    {
      path: `${appRoot}${getPath(PATH_NAMES.resourceTags).link}`,
      label: 'menu.dashboard.resourcetags',
      icon: 'list',
      excludedRoles: [
        ROLES.ORG_CO_MANAGER,
        ROLES.ORG_RESOURCE_COLLABORATOR_EVENT,
        ROLES.ORG_RESOURCE_COLLABORATOR_MENTAL_HEALTH
      ]
    },
    {
      path: `${appRoot}${getPath(PATH_NAMES.navflow).link}`,
      label: 'menu.dashboard.navflow',
      // label: 'Navflow',
      icon: 'list',
      excludedRoles: [
        ROLES.ORG_CO_MANAGER,
        ROLES.ORG_RESOURCE_COLLABORATOR_EVENT,
        ROLES.ORG_RESOURCE_COLLABORATOR_MENTAL_HEALTH
      ]
    },
    {
      path: `${appRoot}${getPath(PATH_NAMES.orgs).link}`,
      label: 'menu.dashboard.orgs',
      icon: 'list',
      excludedRoles: [
        ROLES.ORG_CO_MANAGER,
        ROLES.ORG_RESOURCE_COLLABORATOR_EVENT,
        ROLES.ORG_RESOURCE_COLLABORATOR_MENTAL_HEALTH
      ]
    },
    {
      path: `${appRoot}${getPath(PATH_NAMES.admins).link}`,
      label: 'menu.dashboard.admins',
      icon: 'list',
      excludedRoles: [
        ROLES.ORG_CO_MANAGER,
        ROLES.ORG_RESOURCE_COLLABORATOR_EVENT,
        ROLES.ORG_RESOURCE_COLLABORATOR_MENTAL_HEALTH
      ]
    },
    {
      path: `${appRoot}${getPath(PATH_NAMES.students).link}`,
      label: 'menu.dashboard.students',
      icon: 'list',
      excludedRoles: [
        ROLES.ORG_CO_MANAGER,
        ROLES.ORG_RESOURCE_COLLABORATOR_EVENT,
        ROLES.ORG_RESOURCE_COLLABORATOR_MENTAL_HEALTH
      ]
    }
  ],
  sidebarItems: [],
};
export default routesAndMenuItems;
