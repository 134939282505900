import { memoize } from "lodash";

import BaseModel from './base-model';

class Org extends BaseModel {
  constructor(apiOrg) {
    super()
    
    this.id = apiOrg.id
    this.appId = apiOrg.appId
    this.timezone = apiOrg.timezone
    this.allowedEmailDomains = apiOrg.allowedEmailDomains
    this.supportedLanguages = apiOrg.supportedLanguages || []
    this.url = apiOrg.url
    this.emergencySupportInfo = apiOrg.emergencySupportInfo
    this.name = apiOrg.name;
    this.gymTimeLink = apiOrg.gymTimeLink;
    this.snowflake = apiOrg.snowflake;
  }

  static fromArray(arrayOfOrgs = []) {
    return arrayOfOrgs.map(org => new Org(org))
  }

  getLocaleName = memoize(locale => {
    return this._extract_locale_text(this.name, locale)
  })

  getEnglishName = memoize(() => {
    return this._extract_locale_text(this.name, 'en-US')
  }) 
}

export default Org;