import { get } from "lodash";
import axios from 'axios';

import client from "./client";

import AdminModel from "models/admin";
import OrgModel from "models/org";
import Resource from "models/resource";
import ResourceTagsModel from "models/resource-tags";
import ResourceTagModel from "models/resource-tag";
import ProfileModel from "models/profile";
import StudentModel from "models/student";

import { BASE_URL } from "util/config";


const FETCH_ALL_PAGE_LIMIT = 100;
const DEFAULT_RESOURCES_PAGE_SIZE = 10;

export class BaseApi {
  // This method fetches all the pages for a certain query
  async _fetchAll(url, params) {
    let result = []
    let pageSize = -1;
    let page = 0

    for (page = 0; pageSize !== 0; page++) {
      params.page = page;
      params.limit = FETCH_ALL_PAGE_LIMIT;
      pageSize = 0
      const res = await client.get(url, params);

      if (res && res.data) {
        pageSize = res.data.items.length
        result = result.concat(res.data.items)
      }
    }

    return {
      data: {
        items: result,
        total: result.length
      }
    }
  }

  async _get(url, params, options) {
    let result = {}
    if (options.fetchAll) {
      result = await this._fetchAll(url, params)
    } else {
      result = await client.get(url, params)
    }
    return result
  }  

  async _getResources(type, params = {}, options = {}) {
    if (type || params.types) {
      params.types = type || params.types;
    }
    params.limit = params.limit || DEFAULT_RESOURCES_PAGE_SIZE;

    const result = await this._get("/resources", params, options)

    return {
      data: Resource.fromArray(get(result, 'data.items', [])),
      total: get(result, 'data.total', 0)
    }
  }

  async getResourceTags(type, params = {}, options = {}) {
    params.types = type || params.types;

    const result = await this._get("/resourcetags", params, options)

    return {
      data: new ResourceTagsModel(get(result, 'data.items', [])),
      total: get(result, 'data.total', 0)
    }
  }
}


class Resources extends BaseApi {
  DEFAULT_PAGE_SIZE = DEFAULT_RESOURCES_PAGE_SIZE

  async createResource(params = {}) {
    return await client.put('/resources', params)
  }

  async deleteResource(resourceId) {
    return await client.delete(`/resources/${resourceId}`)
  }

  async updateResource(resourceId, body = {}, options = {}) {
    const result = await client.post(`/resources/${resourceId}`, body, options)
    result.data = new Resource(result.data)
    return result;
  }

  async getResource(resourceId, params = {}, options = {}) {
    const result = await client.get(`/resources/${resourceId}`, params, options)
    result.data = new Resource(result.data)
    return result;
  }
  
  async getAllResources(params = {}, options = {}) {
    return await this._getResources('', params, options)
  }

  async getMentalHealthResources(params = {}, options = {}) {
    return await this._getResources(Resource.TYPES.MENTAL_HEALTH, params, options)
  }

  async getMindfulnessResources(params = {}, options = {}) {
    return await this._getResources(Resource.TYPES.MINDFULNESS, params, options)
  }

  async getOnDemandResources(params = {}, options = {}) {
    return await this._getResources(Resource.TYPES.FITNESS_ON_DEMAND, params, options)
  }

  async getOnCampusResources(params = {}, options = {}) {
    return await this._getResources(Resource.TYPES.FITNESS_ON_CAMPUS, params, options)
  }

  async getClubResourceResources(params = {}, options = {}) {
    return await this._getResources(Resource.TYPES.CLUB_RESOURCE, params, options)
  }

  async getClubResources(params = {}, options = {}) {
    return await this._getResources(Resource.TYPES.CLUB, params, options)
  }

  async getEventResources(params = {}, options = {}) {
    return await this._getResources(Resource.TYPES.EVENT, params, options)
  }

  async getQuickLinkResources(params = {}, options = {}) {
    return await this._getResources(Resource.TYPES.QUICK_LINK, params, options)
  }

  async getLiveStreamResources(params = {}, options = {}) {
    return await this._getResources(Resource.TYPES.FITNESS_LIVE_STREAM, params, options)
  }
}

class ResourceTags extends BaseApi {
  DEFAULT_PAGE_SIZE = DEFAULT_RESOURCES_PAGE_SIZE

  async createResourceTag(params = {}) {
    return await client.put('/resourcetags', params)
  }

  async deleteResourceTag(resourceTagId) {
    return await client.delete(`/resourcetags/${resourceTagId}`)
  }

  async updateResourceTag(resourceTagId, body = {}, options = {}) {
    const result = await client.post(`/resourcetags/${resourceTagId}`, body, options)
    result.data = new ResourceTagModel(result.data)
    return result;
  }

  async getAll(params = {}, options = {}) {
    return await this.getResourceTags('', params, options)
  }

  async getAllNavflowTags(params = {}, options = {}){
    const result = await this._get('/navflowresourcetags/', params, options)
    return result.data
  }

  async getResourceTag(resourceTagId, params = {}, options = {}) {
    const result = await this._get(`/resourcetags/${resourceTagId}`, params, options)
    result.data = new ResourceTagModel(result.data)
    return result;
  }

  async getClubResourceTags(params = {}, options = {}) {
    return await this.getResourceTags(Resource.TYPES.CLUB, params, options);
  }

  async getClubResourceResourceTags(params = {}, options = {}) {
    return await this.getResourceTags(Resource.TYPES.CLUB_RESOURCE, params, options);
  }

  async getOnCampusResourceTags(params = {}, options = {}) {
    return await this.getResourceTags(Resource.TYPES.FITNESS_ON_CAMPUS, params, options);
  }

  async getOnDemandResourceTags(params = {}, options = {}) {
    return await this.getResourceTags(Resource.TYPES.FITNESS_ON_DEMAND, params, options);
  }

  async getMindfulnessResourceTags(params = {}, options = {}) {
    return await this.getResourceTags(Resource.TYPES.MINDFULNESS, params, options);
  }

  async getMentalHealthResourceTags(params = {}, options = {}) {
    return await this.getResourceTags(Resource.TYPES.MENTAL_HEALTH, params, options);
  }
}


class Orgs extends BaseApi {
  async createOrg(body= {}) {
    return await client.put('/orgs', body)
  }

  async deleteOrg(orgId) {
    return await client.delete(`/orgs/${orgId}`)
  }

  async updateOrg(orgId, body = {}, options = {}) {
    const result = await client.post(`/orgs/${orgId}`, body, options)
    result.data = new OrgModel(result.data)
    return result;
  }

  async getAll(params = {}, options = {}) {
    const result = await this._get('/orgs', params, options)
    result.data = OrgModel.fromArray(result.data.items)
    return result;
  }

  async getOrg(orgId, params = {}, options = {}) {
    const result = await this._get(`/orgs/${orgId}`, params, options)
    result.data = new OrgModel(result.data)
    return result;
  }
}


class Users extends BaseApi {
  async getProfile() {
    const resp = await client.get('/admins/profile')

    return new ProfileModel(resp.data)
  }

  async updateProfile(body = {}, options = {}) {
    const result = await client.post('/admins/profile', body, options)
    result.data = new ProfileModel(result.data)
    return result;
  }
}


class Admins extends BaseApi {
  async getAdmins(params = {}, options = {}) {
    const result = await this._get('/admins', params, options)
    result.data.items = AdminModel.fromArray(result.data.items)
    return result;
  }

  async getAdmin(adminId, params = {}, options = {}) {
    const result = await this._get(`/admins/${adminId}`, params, options)
    result.data = new AdminModel(result.data)
    return result;
  }

  async updateAdmin(adminId, body = {}, options = {}) {
    const result = await client.post(`/admins/${adminId}`, body, options)
    result.data = new AdminModel(result.data)
    return result;
  }

  async inviteAdmin(body = {}, options = {}) {
    const result = await client.put('/admins', body, options)
    result.data = new AdminModel(result.data)
    return result;
  }
}


class Tokens extends BaseApi {
  async getToken(uuid) {
    const instance = axios.create({
      baseURL: BASE_URL
    });
    return await instance.get(`/oauth2/auth/accesstoken/${uuid}`)
  }
}


class Students extends BaseApi {
  async getStudents(params = {}, options = {}) {
    const result = await this._get('/students', params, options)
    const resp = result.data
    resp.data = StudentModel.fromArray(resp.items)
    return resp;
  }
}


export const adminsApi = new Admins()
export const usersApi = new Users()
export const orgsApi = new Orgs()
export const resourcesApi = new Resources()
export const resourceTagsApi = new ResourceTags()
export const tokensApi = new Tokens()
export const studentsApi = new Students()
