import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Card, Spinner } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as yup from 'yup';
import { merge } from 'lodash';

import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { orgsApi } from 'api';
import withPathId from 'hoc/withPathId';
import formikWrapper from 'wrappers/formik';
import { getPath, PATH_NAMES } from 'util/urls';

import { mapFieldsToValue, buildPostBody, buildFields, buildResourceFields } from '../../helpers';
import { FIELDS } from './constants';


const OrgPage = ({ orgId }) => {

  const title = 'Organization';
  const description = 'Org Description';

  const [org, setOrg] = useState(null)
  const [loading, setLoading] = useState(true)
  let orgFields;

  useEffect(() => {
    (async () => {
      setLoading(true)

      if (orgId) {
        try {
          const result = await orgsApi.getOrg(orgId)
          setOrg(result.data)
        } catch(e) {
          console.log(e)
        }
      }

      setLoading(false)
    })()
  }, [orgId])

  const isUpdate = !!orgId;

  let _fields = { ...FIELDS };

  if (loading) {
    return (
      <Spinner animation="border" size="sm" variant="primary" className="dzu-spinner-border" />
    )
  }

  if (org) {
    orgFields = buildResourceFields(_fields, org)
  }
  
  const builtFields = buildFields(_fields, ['en-US', 'fr-CA'])
  
  const inputFields = merge({}, builtFields, orgFields)
  
  const initialValues = mapFieldsToValue(inputFields, 'default')

  const validationSchema = mapFieldsToValue(inputFields, 'validation')

  const onSubmit = async (values) => {
    const body = buildPostBody(values, org)

    try {
      if (isUpdate) {
        await orgsApi.updateOrg(orgId, body)
        toast('Org updated')
      } else {
        // sends
        const result = await orgsApi.createOrg(body)
        const newOrgId = result.data.id
        const redirect = `${window.location.origin}/#/dashboard/orgs/org/${newOrgId}`
        toast(<>Success! <a href={redirect}>{`Go to --> '${body.appId}'`}</a></>)
      }
    } catch(e) {
      toast(`Something went wrong: ${e}`)
    }
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      
      <div className="page-title-container">
        <Row className="g-0">
          <Col className="col-auto mb-3 mb-sm-0 me-auto">
            <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to={getPath(PATH_NAMES.orgs).link}>
              <CsLineIcons icon="chevron-left" size="13" />
              <span className="align-middle text-small ms-1">Orgs</span>
            </NavLink>
            <h1 className="mb-0 pb-0 display-4" id="title">
              {title}
            </h1>
          </Col>

        </Row>
      </div>
      <Row>
        <Col xl="12">
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={yup.object().shape(validationSchema)}
          >
            {formikWrapper(({ wrapperProps, formikProps }) => {
              const { getInputFromType } = wrapperProps
              const { handleSubmit } = formikProps

              const fieldKeys = Object.keys(inputFields)

              return (
                <form onSubmit={handleSubmit}>
                  <>
                    <Card className="mb-5">
                      <Card.Body>
                        {fieldKeys.map(fieldKey => {
                          const field = inputFields[fieldKey]
                          if (field.skip) return null;
                          
                          return(
                            <div className="mb-3" key={fieldKey}>
                              {getInputFromType(field.inputType, fieldKey)}
                            </div>
                          )
                        })}
                      </Card.Body>
                    </Card>
                    <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-center mb-2 mb-sm-0 order-sm-3">
                      <Button variant="outline-primary" className="btn-icon btn-icon-start w-100 w-md-auto" type='submit'>
                        <CsLineIcons icon="save" /> {isUpdate ? <span>Update</span> : <span>Create</span>}
                      </Button>
                    </Col>
                  </>
                </form>
              )
            }, inputFields)}
          </Formik>
        </Col>
      </Row>
      <a href="https://docs.google.com/document/d/1NbcnPkZwabWcxofB3NtXIEpgWw33VKj0AhXcilvJ0t0/edit?usp=sharing">Emergency Info Docs</a>
    </>
  );
};

export default withPathId({ pathSlugs:['orgId'] })(OrgPage);
