import get from 'lodash/get';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { Editor } from "@tinymce/tinymce-react";


export const FORMIK_INPUT_TYPES = {
  TEXT: 'TEXT',
  DATE_TIME: 'DATE_TIME',
  NUMBER: 'NUMBER',
  TEXT_AREA: 'TEXT_AREA',
  CHECKBOX: 'CHECKBOX',
  ARRAY: 'ARRAY',
  RICH_TEXT: 'RICH_TEXT'
}

const formikWrapper = (child, fields) => (formikProps) => {
  const {
    values,
    errors,
    handleChange,
    setFieldValue
  } = formikProps;

  const getInputFromType = (inputType, ...opts) => {
    switch(inputType) {
      case FORMIK_INPUT_TYPES.TEXT:
        return getTextInputFormGroup(...opts);
      case FORMIK_INPUT_TYPES.NUMBER:
        return getNumberInputFormGroup(...opts);
      case FORMIK_INPUT_TYPES.DATE_TIME:
        return getDateTimeFormGroup(...opts);
      case FORMIK_INPUT_TYPES.TEXT_AREA:
        return getTextAreaFormGroup(...opts);
      case FORMIK_INPUT_TYPES.RICH_TEXT:
        return getRichTextFormGroup(...opts)
      case FORMIK_INPUT_TYPES.CHECKBOX:
        return getCheckboxInputFormGroup(...opts);
      case FORMIK_INPUT_TYPES.ARRAY:
        return getArrayInputFormGroup(...opts)
      default:
        return getTextInputFormGroup(...opts);
    }
  }

  const getArrayInputFormGroup = (key, props) => {
    const cProps = get(props, 'childProps', {});
    const title = get(fields, `${key}.name`, '')

    const parseValues = (key) => {
      let output = ''

      if (!values || !values[key]) return output

      if (Array.isArray(values[key])){
        values[key].forEach((x, i) => {
          output += x.trim()
          if (i !== values[key].length-1){
            output += ","
          }
        })
      }
      return output
    }

    const onChange = (e) => {
      let arr = e.target.value.split(",")
      setFieldValue(key, arr)
    }

    return (
      <>
        <Form.Label>{title}</Form.Label>
        <Form.Control
          type="text"
          value={parseValues(key)}
          onChange={onChange}
          {...cProps}
        />
        <Form.Text className='text-danger'>
          {errors[key]}
        </Form.Text>
      </>
    )

  }

  const getNumberInputFormGroup = (key, props) => {
    const cProps = get(props, 'childProps', {});
    const title = get(fields, `${key}.name`, '')

    return (
      <>
        <Form.Label>{title}</Form.Label>
        <Form.Control
          type="number"
          value={values ? values[key] : ''}
          onChange={handleChange(key)}
          {...cProps}
        />
        <Form.Text className='text-danger'>
          {errors[key]}
        </Form.Text>
      </>
    )
  }

  const getTextAreaFormGroup = (key, props) => {
    const cProps = get(props, 'childProps', {});
    const title = get(fields, `${key}.name`, '')
    const height = get(fields, `${key}.height`, 150)
    
    return (
      <>
        <Form.Label>{title}</Form.Label>
        <Form.Control
          as='textarea'
          value={values ? values[key] : ''}
          onChange={handleChange(key)}
          style={{ minHeight: height }}
          {...cProps}
        />
        <Form.Text className='text-danger'>
          {errors[key]}
        </Form.Text>
      </>
    )
  }

  const getRichTextFormGroup = (key, props) => {
    const cProps = get(props, 'childProps', {});
    const title = get(fields, `${key}.name`, '')
    const height = get(fields, `${key}.height`, 150)
    
    return (
      <>
        <Form.Label>{title}</Form.Label>
        <Editor 
          apiKey='jhezf0in2zkyollubs0tv074lpxvpy7kwaocz5jmu71mhu9j'
          value={values ? values[key] : ''}
          onEditorChange={(content) => handleChange({
            target: { name: key, value: content }
          })}
        />
        <Form.Text className='text-danger'>
          {errors[key]}
        </Form.Text>
      </>
    )
  }

  const getTextInputFormGroup = (key, props) => {
    const cProps = get(props, 'childProps', {});
    const title = get(fields, `${key}.name`, '')

    return (
      <>
        <Form.Label>{title}</Form.Label>
        <Form.Control
          type="text"
          value={values ? values[key] : ''}
          onChange={e => setFieldValue(key, e.target.value)}
          {...cProps}
        />
        <Form.Text className='text-danger'>
          {errors[key]}
        </Form.Text>
      </>
    )
  }

  const getCheckboxInputFormGroup = (key, props) => {
    const cProps = get(props, 'childProps', {});
    const title = get(fields, `${key}.name`, '')

    return (
      <>
        <Form.Label>{title}</Form.Label>
        <Form.Check
          type="checkbox"
          checked={values && values[key]}
          onChange={e => {
            if (!cProps.disabled) {
              setFieldValue(key, e.target.checked)
            }
          }}
          {...cProps}
        />
        <Form.Text className='text-danger'>
          {errors[key]}
        </Form.Text>
      </>
    )
  }


  const getDateTimeFormGroup = (key, props) => {
    const cProps = get(props, 'childProps', {});
    const dateFormat = 'YYYY-MM-DDThh:mm';
    const title = get(fields, `${key}.name`, '')
    const value = moment(parseInt(values[key])).format(dateFormat)
    const onChange = (e) => {
      setFieldValue(key, `${moment(e.target.value).valueOf()}`)
    }

    return (
      <>
        <Form.Label>{title}</Form.Label>
        <Form.Control
          type="datetime-local"
          value={value}
          onChange={onChange}
          {...cProps}
        />
        <Form.Text className='text-danger'>
          {errors[key]}
        </Form.Text>
      </>
    )
  }


  return (
    <>
      {child({
        wrapperProps: {
          getDateTimeFormGroup,
          getTextInputFormGroup,
          getNumberInputFormGroup,
          getTextAreaFormGroup,
          getCheckboxInputFormGroup,
          getInputFromType
        },
        formikProps
      })}
    </>
  )
}

export default formikWrapper;