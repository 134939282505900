import React from 'react';
import { Row, Col, Card } from 'antd';
import HtmlHead from 'components/html-head/HtmlHead';
import BreadcrumbList from 'components/breadcrumb-list/BreadcrumbList';
import useCustomLayout from 'hooks/useCustomLayout';
import { MENU_PLACEMENT, LAYOUT } from 'constants.js';

const VerticalPage = () => {
  const title = 'Compass Admin Dashboard';
  const description = '';

  const breadcrumbs = [{ to: '', text: 'Home' }];

  useCustomLayout({ placement: MENU_PLACEMENT.Vertical, layout: LAYOUT.Fluid });
  return (
    <>
      <HtmlHead title={title} description={description} />
      
      {/* Title Start */}
      <section className="scroll-section" id="title">
        <div className="page-title-container">
          <h1 className="mb-0 pb-0 display-4">{title}</h1>
          <BreadcrumbList items={breadcrumbs} />
        </div>
        <Row>
          <Col>
            <Card title="" >
              {description}
            </Card>
          </Col>
        </Row>
      </section>
      {/* Title End */}
        
    </>
  );
};

export default VerticalPage;
