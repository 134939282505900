import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Clamp from 'components/clamp';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

import HtmlHead from 'components/html-head/HtmlHead';
import BreadcrumbList from 'components/breadcrumb-list/BreadcrumbList';
import Pagination from 'components/pagination';
import useCustomLayout from 'hooks/useCustomLayout';
import { MENU_PLACEMENT, LAYOUT } from 'constants.js';
import LoadingWrapper from 'wrappers/loading';
import { getPath, PATH_NAMES } from 'util/urls';
import AuthContext from 'contexts/authContext';

import { resourceTagsApi } from 'api';

import FilterModal from './FilterModal';

import './index.scss';
import { get } from 'lodash';


const PAGE_SIZE = 7

const Resources = ({ history }) => {
  const [resourcesTags, setResourcesTags] = useState([]);
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0);
  const [numOfItems, setNumOfItems] = useState(0);
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [filter, setFilter] = useState({})

  const { profile } = useContext(AuthContext)

  const title = 'Resource Tags';
  const description = 'An empty page with a fluid vertical layout.';

  const breadcrumbs = [{ to: '/dashboard', text: 'Dashboard' }, { to: '', text: 'Resource Tags' }];

  useCustomLayout({ placement: MENU_PLACEMENT.Vertical, layout: LAYOUT.Fluid });

  useEffect(() => {
    (async () => {
      setLoading(true)

      console.log("filter", filter)

      try {
        const resourcetags = await resourceTagsApi.getAll({ page, limit: PAGE_SIZE, ...filter })

        setResourcesTags(resourcetags.data)
        setNumOfItems(resourcetags.total)
      } catch(e) {}

      setLoading(false)
    })()
  }, [page, filter])

  const onNewResourceTag = () => {
    history.push(getPath(PATH_NAMES.addResourceTag).link)
  }


  const onFilter = () => {
    setIsFilterOpen(true)
  }

  const handleFilterClose = () => {
    setIsFilterOpen(false)
  }

  const handleFilterApply = (appliedFilter = {}) => {
    setFilter(appliedFilter)
  }


  return (
    <>
      <HtmlHead title={title} description={description} />
      <Row>
        <Col>
          {/* Title Start */}
          <section className="scroll-section" id="title">
            <div className="page-title-container">
              <Row className="g-0">
                {/* Title Start */}
                <Col className="col-auto mb-3 mb-sm-0 me-auto">
                  <h1 className="mb-0 pb-0 display-4">{title}</h1>
                  <BreadcrumbList items={breadcrumbs} />
                </Col>
                {/* Title End */}

                {/* Top Buttons Start */}
                <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3">
                  <Row>
                    <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3">
                      <Button onClick={onFilter} variant="outline-primary" className="btn-icon btn-icon-start w-100 w-md-auto">
                        <CsLineIcons icon="plus" /> <span>Filters</span>
                      </Button>
                    </Col>
                    {profile && profile.permission.canCreateAndUpdateResourceTags() &&
                      <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3">
                        <Button onClick={onNewResourceTag} variant="outline-primary" className="btn-icon btn-icon-start w-100 w-md-auto">
                          <CsLineIcons icon="plus" /> <span>Create Resource Tag</span>
                        </Button>
                      </Col>
                    }
                  </Row>
                </Col>
                {/* Top Buttons End */}
              </Row>
            </div>
        
            <div className="mb-n2 resources__container">
              <LoadingWrapper loading={loading}>
                {get(resourcesTags, 'tags', []).map(resourceTag => {
                  return (
                    <Card className="mb-2" key={resourceTag.id}>
                      <Row className="g-0 h-auto sh-sm-18">
                        <Col xs="12" className="col-sm">
                          <Card.Body className="h-100">
                            <NavLink to={getPath(PATH_NAMES.editResourceTag, { resourceTagId: resourceTag.id }).link} className="d-flex flex-column mb-lg-0 mb-3 mb-lg-0 pe-3">
                              <div className="lh-1 mb-3 heading">{resourceTag.getLocaleTitle('en-US')}</div>
                              <Clamp className="text-medium text-muted mb-2" clamp="2">
                                Group: {resourceTag.getLocaleGroup('en-US')}
                              </Clamp>
                              <Clamp className="text-medium text-muted mb-2" clamp="2">
                                Resource Type: {resourceTag.getTypeNames()}
                              </Clamp>
                              { resourceTag.sortDimensionCampus &&
                                <Clamp className="text-medium text-muted mb-2" clamp="2">
                                  Sort Dimension (Campus): {resourceTag.sortDimensionCampus ? resourceTag.sortDimensionCampus : "none"}
                                </Clamp>
                              }
                            </NavLink>
                          </Card.Body>
                        </Col>
                      </Row>
                    </Card>
                  )
                })}
              </LoadingWrapper>
            </div>
            <div className='resources__pagination'>
              <Pagination
                currentPage={page + 1}
                onPage={page => setPage(page - 1)}
                total={numOfItems}
                pageSize={PAGE_SIZE}
              />
            </div>
          </section>
          {/* Title End */}
          <FilterModal
          isOpen={isFilterOpen}
          onClose={handleFilterClose}
          onApply={handleFilterApply}
          />
        </Col>
      </Row>
    </>
  );
};

export default Resources;
