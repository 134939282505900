import { useEffect, useState } from 'react';
import { Input, Modal, DatePicker, Divider, Select, Typography } from 'antd';
import moment from 'moment';


import SelectTypeInput from 'components/select-type-input';


const { RangePicker } = DatePicker;
const { Text } = Typography;
const { Option } = Select;


const FilterModal = ({ isOpen, onClose, onApply, tags }) => {
  const [typeValue, setTypeValue] = useState([])
  const [selectedTags, setSelectedTags] = useState([])
  const [endTime, setEndTime] = useState(null)
  const [startTime, setStartTime] = useState(null)
  const [resourceName, setResourceName] = useState('')
  const [currentState, setCurrentState] = useState({})

  useEffect(() => {
    setTypeValue(currentState.types || [])
    
    if (currentState.startTime) {
      setStartTime(currentState.startTime)
    }
    if (currentState.endTime) {
      setEndTime(currentState.endTime)
    }

    setSelectedTags(currentState.tags || [])

    setResourceName(currentState.q)
  }, [currentState])

  const tagsObject = Object.assign({}, ...tags.map(tag => ({ [tag.id]: tag })))

  const handleSelect = (value) => {
    setTypeValue([...typeValue, value])
  }

  const handleDeselect = (value) => {
    setTypeValue([...typeValue.filter(t => t !== value)])
  }

  const handleResourceTagSelect = (tagId) => {
    if (tagsObject[tagId]) {
      setSelectedTags([...selectedTags, tagId])
    }
  }

  const handleResourceTagDeselect = (tagId) => {
    setSelectedTags([...selectedTags.filter(_tagId => _tagId !== tagId)])
  }

  const handleApply = () => {
    const filter = {}

    if (typeValue.length) {
      filter['types'] = typeValue
    }

    if (selectedTags.length) {
      filter.tags = selectedTags
    }

    if (startTime) {
      filter.startTime = startTime
    }

    if (endTime) {
      filter.endTime = endTime
    }

    if (resourceName) {
      filter.q = resourceName
    }

    if (onApply) {
      setCurrentState(filter)
      onApply(filter)
    }

    handleClose()
  }

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const handleTimeRangeChange = (dates) => {
    if (dates) {
      setStartTime(dates[0].valueOf())
      setEndTime(dates[1].valueOf())
    } else {
      setStartTime(null)
      setEndTime(null)
    }
  }

  const handleNameQueryChange = e => {
    setResourceName(e.target.value)
  }

  return (
    <Modal
      title="Resource Filters"
      visible={isOpen}
      onOk={handleApply}
      onCancel={handleClose}
      okText='Apply'
    > 
      <Text className='resources__filter-modal__label'> Search by name </Text>
      <Input
        onChange={handleNameQueryChange}
        placeholder="Search resource name"
        value={resourceName}
      />

      <Divider plain />

      <Text className='resources__filter-modal__label'> Start Time -- End Time </Text>
      <RangePicker
        onChange={handleTimeRangeChange}
        showTime
        value={[
          startTime ? moment(startTime) : null,
          endTime ? moment(endTime) : null
        ]}
      />

      <Divider plain />

      <Text className='resources__filter-modal__label'> Resource Types </Text>
      <SelectTypeInput
        value={typeValue}
        handleSelect={handleSelect}
        handleDeselect={handleDeselect}
      />

      <Divider plain />

      <Text className='resources__filter-modal__label'> Resource Tags </Text>
      <Select
        mode="multiple"
        style={{ width: '100%' }}
        placeholder="select a resource tag"
        onDeselect={handleResourceTagDeselect}
        onSelect={handleResourceTagSelect}
        optionLabelProp="label"
        value={selectedTags}
        allowClear
        showSearch
        filterOption={(input, option) => {
          if (input) {
            return option.label.toLowerCase().includes(input.toLowerCase())
          }
        }}
      >
        {tags.map(tag => {
          return (
            <Option value={tag.id} label={tag.getEnglishTitle()}>
              <div>
                {tag.getEnglishTitle()}
              </div>
            </Option>
          )
        })}
      </Select>
    </Modal>
  );
};

FilterModal.defaultProps = {
  tags: []
}

export default FilterModal;