import { Pagination } from 'antd';

const CPagination = (props) => {
  const currentPage = props.currentPage
  const total = Math.ceil(props.total);
  const pageSize = Math.ceil(props.pageSize);
  const onPage = props.onPage;

  return (
    <Pagination
      current={currentPage}
      onChange={onPage}
      total={total}
      pageSize={pageSize}
      showSizeChanger={false}
    />
  )
}

CPagination.defaultProps = {
  currentPage: 0,
  pageSize: 0,
  total: 0
}

export default CPagination;