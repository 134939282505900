import moment from 'moment';
import { RRule } from 'rrule'

import { REPEAT_FREQUENCY } from 'components/recurrence-picker/constants'

class RecurringRule {
  ruleObj = {}

  constructor(apiRecurrenceRule) {
    this.ruleObj.startdate = apiRecurrenceRule.startdate ? moment(apiRecurrenceRule.startdate) : null
    console.log("DATETIME (?) what startdate are we getting for old events:", apiRecurrenceRule.startdate)
    // this.ruleObj.startdate = apiRecurrenceRule.startdate ? apiRecurrenceRule.startdate : null
    this.ruleObj.byweekday = apiRecurrenceRule.byweekday
    this.ruleObj.bymonth = apiRecurrenceRule.bymonth
    this.ruleObj.until = apiRecurrenceRule.until
    this.ruleObj.interval = apiRecurrenceRule.interval || 1
    this.ruleObj.count = apiRecurrenceRule.count || 1 
    this.ruleObj.frequency = apiRecurrenceRule.frequency || 'YEARLY'
  }

  static fromArray(arrayOfRecurringRules = []) {
    return arrayOfRecurringRules.map(recurringRule => new RecurringRule(recurringRule))
  }

  convertToRRule() {
    const rrule = this.getCleanRule()

    if (rrule.frequency) {
      rrule.freq = this._getFrequencyNum(rrule.frequency)
    }

    if (rrule.byweekday) {
      rrule.byweekday = rrule.byweekday.map(wd => this._getWeekdayNumNum(wd))
    }

    if (rrule.startdate) {
      rrule.dtstart = rrule.startdate.toDate()
      console.log("DATETIME (?) rrule.dtstart", rrule.startdate)
    }

    // RRule throws an error without these default fields
    rrule.byhour = []
    rrule.byminute = []
    rrule.bysecond = []

    delete rrule.frequency
    delete rrule.startdate

    return rrule
  }

  _getFrequencyNum(freqString) {
    switch(freqString) {
      case REPEAT_FREQUENCY.YEARLY:
        return 0;
      case REPEAT_FREQUENCY.MONTHLY:
        return 1;
      case REPEAT_FREQUENCY.WEEKLY:
        return 2;
      case REPEAT_FREQUENCY.DAILY:
        return 3;
      default:
        return ''
    }
  }

  _getWeekdayNumNum(weekdayString) {
    switch(weekdayString) {
      case "MO":
        return 0;
      case "TU":
        return 1;
      case "WE":
        return 2;
      case "TH":
        return 3;
      case "FR":
        return 4;
      case "SA":
        return 5;
      case "SU":
        return 6;
      default:
        return ''
    }
  }

  /**
   * This returns the rule after removing all undefined fields
   */
  getCleanRule() {
    const _ruleObj = {...this.ruleObj}
    const keys = Object.keys(_ruleObj)

    keys.forEach(key => {
      if (_ruleObj[key] === undefined) {
        delete _ruleObj[key]
      } else if (Array.isArray(_ruleObj[key]) && !_ruleObj[key].length) { //Remove empty arrays
        delete _ruleObj[key]
      }
    })
    return _ruleObj
  }

  getRRule() {
    const rruleObj = this.convertToRRule()
    return new RRule(rruleObj)
  }

  validateCleanRule() {
    const rule = this.getCleanRule()
    const errors = []

    if (!rule.frequency) {
      errors.push(new Error('Frequency is required'))
    } else if (rule.frequency === REPEAT_FREQUENCY.WEEKLY && (!rule.byweekday || !rule.byweekday.length)) {
      errors.push(new Error('Weekday is required'))
    }

    if (errors.length) {
      return errors
    }
  }
}

export default RecurringRule
