import OrgModel from './org';

export default class Student {
  constructor(apiStudent) {
    this.name = apiStudent.name;
    this.yearOfStudy = apiStudent.yearOfStudy;
    this.org = new OrgModel(apiStudent.orgId)
  }

  static fromArray(arrayOfStudents = []) {
    return arrayOfStudents.map(student => new Student(student))
  }
}
