import { useContext, useState } from 'react';
import { Button, Card, Col, Form } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { get, merge } from 'lodash';
import { Button as AntdButton, DatePicker, Modal } from 'antd';
import ReactSelect from 'react-select';
import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { RRule } from 'rrule'
import { toast } from 'react-toastify';
import moment from 'moment';

import RecurringRule from 'models/recurring-rule';

import CsLineIcons from 'cs-line-icons/CsLineIcons';

import formikWrapper from 'wrappers/formik';
import Resource from 'models/resource';
import CustomDatePicker from 'components/recurrence-picker';
import AuthContext from 'contexts/authContext';

import { mapFieldsToValue, buildFields, buildResourceFields, capitalizeFirstLetter } from '../../../helpers';

import { FIELDS as BASE_FIELDS } from './constants'
import LogicField from './LogicField'
import { ORG_SCOPES } from 'models/permission';


// const { Option } = Select;


const SUPPORTED_LOCALES = ['en-US', 'fr-CA']
const DEFAULT_LOCALE = 'en-US'


const RecurrentDate = ({ recurringRule, onRecurrenceSelected, onRemoveDate, disabled }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onCancelModal = () => { setIsModalOpen(false) }
  const onClickDate = () => { setIsModalOpen(true) }

  const rrule = recurringRule.convertToRRule()

  if (rrule.until) {
    rrule.until = new Date(rrule.until)
  }

  const rule = new RRule(rrule)

  return (
    <li>
      <div>
        <AntdButton onClick={onClickDate} type="text" disabled={disabled}>
          {
            capitalizeFirstLetter(rule.toText())
          }
        </AntdButton>
        <AntdButton icon={<CloseCircleOutlined />} onClick={onRemoveDate} type="text" danger/>
      </div>
      <Modal title="Recurrent Date" visible={isModalOpen} onCancel={onCancelModal} footer={null}>
        <CustomDatePicker
          addRecurrence={true}
          recurrenceRule={recurringRule}
          onChange={rrule => {
            onRecurrenceSelected(rrule)
          }}
        />
      </Modal>
    </li>
  )
}

const RegularDate = ({ date, onDateSelected, onRemoveDate, disabled }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onCancelModal = () => { setIsModalOpen(false) }
  const onClickDate = () => { setIsModalOpen(true) }

  return (
    <li>
      <div>
        <AntdButton onClick={onClickDate} type="text" disabled={disabled}>
          {
            moment(date).format('MMMM Do YYYY [at] h:mm:ss a')
          }
        </AntdButton>
        <AntdButton icon={<CloseCircleOutlined />} onClick={onRemoveDate} type="text" danger/>
      </div>
      <Modal title="Regular Date" visible={isModalOpen} onCancel={onCancelModal} footer={null}>
        <DatePicker
          onChange={(_date) => {/* console.log("raw regular", _date); */ onDateSelected(_date)}}
          value={date && moment(date)}
          disabled={disabled}
          showTime
          use12Hours
        />
      </Modal>
    </li>
  )
}


const BaseInputs = ({ addRecurrence , tags, fields, type, loading, resource, onSubmit, orgs = [] }) => {
  const { profile } = useContext(AuthContext)

  let tagsObject = {}
  let resourceFields = {}
  const isUpdate = !!resource;

  const _fields = { ...BASE_FIELDS, ...fields }


  if (tags.length) {
    tagsObject = Object.assign({}, ...tags.map(tag => ({ [tag.id]: tag })))
  }

  if (resource) {
    resourceFields = buildResourceFields(_fields, resource)
  }

  const builtFields = buildFields(_fields, SUPPORTED_LOCALES)

  const inputFields = merge({}, builtFields, resourceFields)

  if (loading) {
    return null
  }

  const initialValues = mapFieldsToValue(inputFields, 'default')

  const validationSchema = mapFieldsToValue(inputFields, 'validation')

  // If there is a duration, there has to be a date accompanying it
  const showDates = validationSchema.hasOwnProperty('durationMinutes')

  initialValues.type = Resource.TYPES[type]
  
  if (resource && resource.tags) {
    initialValues.tags = resource.tags.map(t => t.id)
  }

  if (resource && resource.dateRules) {
    initialValues.rules = get(resource, 'dateRules.rules', [])
    initialValues.dates = get(resource, 'dateRules.dates', [])
  } else {
    initialValues.dates = []
    initialValues.rules = []
  }

  if (resource && typeof(resource.orgScope) === 'object') {
    initialValues.orgScope = resource.orgScope.id;
  }

  // These fields are required by the backend to create a resource
  if (!initialValues.tags) {
    initialValues.tags = []
    initialValues.onlyForAny = []
    initialValues.onlyForAny2 = []
    initialValues.onlyForAny3 = []
    initialValues.onlyForAll = []
    initialValues.notForAll = []
  }

  const canCreateAndUpdateResource = profile.permission.canCreateAndUpdateResource(type)
  const canApproveResource = profile.permission.canApproveResource()
  const canEditResourceOrg = profile.permission.canEditResourceOrg()


  const _onSubmit = (values, ...props) => {
    let errors;
    const submitValues = { ...values }
    const dateRules = {}

    try {

      for (let i = 0; i < submitValues.rules && submitValues.rules.length; i++) {
        const v = submitValues.rules[i]
        errors = v.validateCleanRule()

        if (errors) {
          errors.forEach(err => {
            toast(
              `${err.message} for "${v.getRRule().toText()}"`
            )
          })

          break;
        }
      }

      if (submitValues.rules && submitValues.rules.length) {
        dateRules.rules = submitValues.rules.map(r => r.getCleanRule())
      }

      if (submitValues.dates && submitValues.dates.length) {
        dateRules.dates = submitValues.dates
      }

      delete submitValues.rules
      delete submitValues.dates

      if (!errors) {
        
        if (showDates) {
          submitValues.dateRules = dateRules
        }
        
        console.log("submitValues final", submitValues)
        console.log("The submitted values are:", submitValues)
        onSubmit(submitValues, ...props)
      }
    } catch(e) {
      toast(e.message)
    }
  }


  return (
    <Formik
      onSubmit={canCreateAndUpdateResource && _onSubmit}
      initialValues={initialValues}
      validationSchema={yup.object().shape(validationSchema)}
    >
      {formikWrapper(({ wrapperProps, formikProps }) => {
        let selectedOption;
        const { getInputFromType, getCheckboxInputFormGroup } = wrapperProps
        const { handleSubmit, setFieldValue, values } = formikProps

        const fieldKeys = Object.keys(inputFields)

        const onAddRegularDate = () => {
          setFieldValue('dates', [...values.dates, Date.now()])
        }

        const onAddRecurringDate = () => {
        //   console.log("onAddRecurringDate")
          setFieldValue('rules', [...values.rules, new RecurringRule({ startdate: new Date() })])
        }

        const allOrgsOption = {
          value: ORG_SCOPES.ALL_ORGS,
          label: 'All orgs'
        }

        const handleOrgChange = (option) => {
          let value = ORG_SCOPES.ALL_ORGS;

          if (option.value !== ORG_SCOPES.ALL_ORGS) {
            value = option.value.id;
          }

          setFieldValue('orgScope', value)
        }

        if (values.orgScope === ORG_SCOPES.ALL_ORGS) {
          selectedOption = allOrgsOption
        } else {
          const org = orgs.find(org => org.id === values.orgScope)

          selectedOption = {
            value: org,
            label: org && org.getEnglishName()
          }
        }
        const orgSelectOptions = orgs.map(org => {
          return {
            value: org,
            label: org.getEnglishName()
          }})
          orgSelectOptions.push(allOrgsOption)

        return (
          <form onSubmit={handleSubmit}>
            <>
              <Card className="mb-5">
                <Card.Body>
                  {canEditResourceOrg &&
                    <div className='d-flex flex-column mb-3'>
                      <Form.Label> Select Org </Form.Label>
                      <ReactSelect
                        classNamePrefix="react-select"
                        options={orgSelectOptions}
                        value={selectedOption}
                        onChange={handleOrgChange}
                        placeholder="Select here"
                      />
                    </div>
                  }

                  {fieldKeys.map(fieldKey => {
                    const field = inputFields[fieldKey]
                    if (field.skipRender) return null;

                    return(
                      <div className="mb-3" key={fieldKey}>
                        {getInputFromType(
                          field.inputType,
                          fieldKey,
                          {
                            childProps: {
                              disabled: !canCreateAndUpdateResource
                            }
                          })
                        }
                      </div>
                    )
                  })}

                  {showDates &&
                    <div className='d-flex flex-column mb-3'>
                      <Form.Label> Dates </Form.Label>
                      <div className='ms-3'>
                        {addRecurrence &&
                        <div>
                          <p> Recurring Dates </p>
                          <ul>
                            {
                              get(values, 'rules', []).map((recurringRule, idx) => {
                                const onRecurrenceSelected = _rrule => {

                                  const _rules = values.rules;
                                  const _rule = new RecurringRule(_rrule)

                                  const errors  = _rule.validateCleanRule()
                                  if (errors) {
                                    errors.forEach(err => {
                                      toast(err.message)
                                    })
                                  }

                                  _rules[idx] = _rule
                                  setFieldValue('rules', [..._rules])
                                }

                                const onRemoveDate = () => {
                                  let _rules = values.rules;
                                  _rules = _rules.filter((_, index) => {
                                    return index !== idx
                                  })
                                  setFieldValue('rules', [..._rules])
                                }

                                return (
                                  <RecurrentDate
                                    recurringRule={recurringRule}
                                    onRecurrenceSelected={onRecurrenceSelected}
                                    onRemoveDate={onRemoveDate}
                                    disabled={!canCreateAndUpdateResource}
                                    key={idx}
                                  />
                                )
                              })
                            }
                          </ul>
                        </div>
                        }
                        <div>
                          <p> Regular Dates </p>
                          <ul>
                            {
                              get(values, 'dates', []).map((date, idx) => {
                                const onDateSelected = _date => {
                                  const _dates = values.dates;
                                  _dates[idx] = _date
                                  setFieldValue('dates', [..._dates])
                                }

                                const onRemoveDate = () => {
                                  let _dates = values.dates;
                                  _dates = _dates.filter((_, index) => {
                                    return index !== idx
                                  })
                                  setFieldValue('dates', [..._dates])
                                }

                                return (
                                  <RegularDate
                                    date={date}
                                    onDateSelected={onDateSelected}
                                    onRemoveDate={onRemoveDate}
                                    disabled={!canCreateAndUpdateResource}
                                    key={idx}
                                  />
                                )
                              })
                            }
                          </ul>
                        </div>
                      </div>
                      <div className='d-flex'>
                        
                        <AntdButton
                          className='d-flex align-items-center justify-content-center'
                          type='primary'
                          icon={<PlusCircleOutlined />}
                          onClick={onAddRegularDate}
                        >
                          
                          Add Regular Date
                        </AntdButton>
                        {addRecurrence &&
                          <AntdButton
                            className='d-flex align-items-center justify-content-center ms-3'
                            type='primary'
                            icon={<PlusCircleOutlined />}
                            onClick={onAddRecurringDate}
                          >
                            Add Recurring Date
                          </AntdButton>
                        }
                      </div>
                    </div>
                  }

                  < LogicField
                    label={"Relevant for:"}
                    placeholder={"select a resource tag"}
                    fieldName={"tags"}
                    setFieldValue={setFieldValue} values={values} tagsObject={tagsObject} tags={tags} DEFAULT_LOCALE={DEFAULT_LOCALE}
                    disabled={!canCreateAndUpdateResource}
                  />

                  { type === "MENTAL_HEALTH" &&
                    <>
                      < LogicField
                          label={"Exclusively relevant for ALL of the following: "}
                          placeholder={"For the recommendations tool:Only add tags here that you want to be REQUIRED for this resource to be recommended."}
                          fieldName={"onlyForAll"}
                          setFieldValue={setFieldValue} values={values} tagsObject={tagsObject} tags={tags} DEFAULT_LOCALE={DEFAULT_LOCALE}
                          disabled={!canCreateAndUpdateResource}
                      />

                      < LogicField
                          label={"Exclusively relevant for ANY of the following (1)"}
                          placeholder={"For the recommendations tool: Only add tags here that you want to be REQUIRED for this resource to be recommended."}
                          fieldName={"onlyForAny"}
                          setFieldValue={setFieldValue} values={values} tagsObject={tagsObject} tags={tags} DEFAULT_LOCALE={DEFAULT_LOCALE}
                          disabled={!canCreateAndUpdateResource}
                      />

                      < LogicField
                        label={"Exclusively relevant for ANY of the following (2)"}
                        placeholder={"For the recommendations tool: Only add tags here that you want to be REQUIRED for this resource to be recommended."}
                        fieldName={"onlyForAny2"}
                        setFieldValue={setFieldValue} values={values} tagsObject={tagsObject} tags={tags} DEFAULT_LOCALE={DEFAULT_LOCALE}
                        disabled={!canCreateAndUpdateResource}
                      />

                      < LogicField
                        label={"Exclusively relevant for ANY of the following (3)"}
                        placeholder={"For the recommendations tool:Only add tags here that you want to be REQUIRED for this resource to be recommended."}
                        fieldName={"onlyForAny3"}
                        setFieldValue={setFieldValue} values={values} tagsObject={tagsObject} tags={tags} DEFAULT_LOCALE={DEFAULT_LOCALE}
                        disabled={!canCreateAndUpdateResource}
                      />

                      < LogicField
                        label={"Specifically IRRELEVANT to:"}
                        placeholder={"These tags, if they show up in the user's questionnaire, will cause this resource to be hidden"}
                        fieldName={"notForAll"}
                        setFieldValue={setFieldValue} values={values} tagsObject={tagsObject} tags={tags} DEFAULT_LOCALE={DEFAULT_LOCALE}
                        disabled={!canCreateAndUpdateResource}
                      />
                    </>
                  }
                  
                  <div>
                     {getCheckboxInputFormGroup(
                        'approved',
                        {
                           childProps: {
                           disabled: !canApproveResource
                           }
                        }
                     )}
                  </div>
                </Card.Body>
              </Card>
              {canCreateAndUpdateResource &&
                <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-center mb-2 mb-sm-0 order-sm-3">
                  <Button variant="outline-primary" className="btn-icon btn-icon-start w-100 w-md-auto" type='submit'>
                    <CsLineIcons icon="save" /> {isUpdate ? <span>Update</span> : <span>Create</span>}
                  </Button>
                </Col>
              }
            </>
          </form>
        )
      }, inputFields)}
    </Formik>
  )
}

BaseInputs.defaultProps = {
  addRecurrence: true,
  tags: []
}

export default BaseInputs