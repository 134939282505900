import memoize from "lodash/memoize";
import get from "lodash/get";

import Resource from "./resource";
import BaseModel from "./base-model";

class ResourceTag extends BaseModel {
  constructor(apiTag) {
    super();
    this.id = apiTag.id;
    this.resourceTypes = apiTag.resourceTypes;
    this.group = apiTag.group;
    this.title = apiTag.title;
    this.navflowName = apiTag.navflowName
    this.groupObj = this._localeObjArrToObj(apiTag.group)
    this.titleObj = this._localeObjArrToObj(apiTag.title)
    this.orgScope = apiTag.orgScope
    this.sortDimensionCampus = apiTag.sortDimensionCampus
  }

  getLocaleTitle = memoize(locale => {
    const title = this.titleObj[locale];
    if (title) {
      return title.text;
    }
  })
  
  getLocaleGroup = memoize(locale => {
    const group = this.groupObj[locale];
    if (group) {
      return group.text;
    }
  })

  getEnglishTitle() {
    return this.getLocaleTitle('en-US')
  }

  getTypeNames() {
    const types = get(this, 'resourceTypes', [])
    return types.map(t => t.replaceAll('_', ' ')).join(', ')
  }

  isEvent() {
    return this.resourceType === Resource.TYPES.EVENT;
  }

  isQuickLink() {
    return this.resourceType === Resource.TYPES.QUICK_LINK;
  }

  isMentalHealth() {
    return this.resourceType === Resource.TYPES.MENTAL_HEALTH;
  }

  isClub() {
    return this.resourceType === Resource.TYPES.CLUB;
  }

  isClubResource() {
    return this.resourceType === Resource.TYPES.CLUB_RESOURCE;
  }

  isEmergencySupportInfo() {
    return this.resourceType === Resource.TYPES.EMERGENCY_SUPPORT_INFO;
  }
}

export default ResourceTag;