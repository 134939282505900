import { get } from 'lodash';
import BaseModel from './base-model';
import Org from './org';
import Permission, { ORG_SCOPES, ROLES } from './permission';

class Profile extends BaseModel {
  constructor(apiProfile) {
    super()
    
    this.id = apiProfile.id
    this.email = apiProfile.email
    this.name = apiProfile.name
    this.role = apiProfile.role
    this.orgScope = ''

    const orgScopeId = get(apiProfile, 'orgScope.id')

    if (apiProfile.role === ROLES.SUPER_ADMIN && !orgScopeId) {
      this.orgScope = ORG_SCOPES.ALL_ORGS
    } else if (orgScopeId) {
      this.orgScope = orgScopeId
      this.org = new Org(apiProfile.orgScope)
    }

    this.permission = new Permission(this.role, this.orgScope, this.id)
  }
}

export default Profile;