import * as yup from 'yup';

import { FORMIK_INPUT_TYPES } from 'wrappers/formik';

export const FIELDS = {
  resourceTypes: {
    name: 'Resource Types',
    default: [],
    require: true,
    validation: yup.array().of(yup.string()).required().min(1, "Resource Type must have at least one item."),
    skipRender: true
  },
  title: {
    name: 'Title',
    default: '',
    validation: yup.string(),
    require: true,
    localize: true,
    inputType: FORMIK_INPUT_TYPES.TEXT
  },
  orgScope: {
    default: '',
    require: true,
    validation: yup.string(),
    skipRender: true
  },
  group: {
    name: 'Group',
    default: '',
    validation: yup.string(),
    require: true,
    localize: true,
    inputType: FORMIK_INPUT_TYPES.TEXT
  },
  navflowName: {
    name: 'navflowName',
    default: 'none',
    validation: yup.string(),
    inputType: FORMIK_INPUT_TYPES.TEXT
  },
  sortDimensionCampus: {
    name: 'Sorting priority for On Campus / Off Campus / Online',
    default: 0,
    validation: yup.number(),
    inputType: FORMIK_INPUT_TYPES.NUMBER
  },
}
