import { useEffect, useState } from 'react';
import { Modal } from 'antd';
import { Form, Card, Spinner } from 'react-bootstrap';
import { Formik } from 'formik';
import * as yup from 'yup';
import { get, merge } from 'lodash';
import { toast } from 'react-toastify';

import formikWrapper from 'wrappers/formik';

import { adminsApi } from 'api';

import { buildResourceFields, mapFieldsToValue, buildPostBody, buildFields } from 'helpers';

import { FIELDS } from './constants';
import { ROLES } from 'models/permission';


const ProfileModal = ({ adminId, isOpen, onClose, onUpdate, tags }) => {
  const [admin, setAdmin] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    (async () => {
      setLoading(true)

      if (adminId) {
        try {
          const result = await adminsApi.getAdmin(adminId)
          const _admin = result.data;
          setAdmin(_admin)
        } catch(e) {
          console.log(e)
        }
      }

      setLoading(false)
    })()
  }, [adminId])

  let _fields = { ...FIELDS };
  
  if (loading) {
    return (
      <Spinner animation="border" size="sm" variant="primary" className="dzu-spinner-border" />
    )
  }

  let adminFields

  if (admin) {
    adminFields = buildResourceFields(_fields, admin)
  }

  const builtFields = buildFields(_fields)

  const inputFields = merge({}, builtFields, adminFields)

  const initialValues = mapFieldsToValue(inputFields, 'default')

  const validationSchema = mapFieldsToValue(inputFields, 'validation')

  const onSubmit = async (values) => {
    const body = buildPostBody(values)
    
    // TODO: Enter these dynamically
    body.orgScope = admin.orgScope

    try {
      await adminsApi.updateAdmin(adminId, body)
      toast('Profile updated')
    } catch(e) {
      console.log(e)
      toast('Something went wrong')
    }
  }

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={yup.object().shape(validationSchema)}
    >
      {formikWrapper(({ wrapperProps, formikProps }) => {
        const { getInputFromType } = wrapperProps
        const { handleSubmit, values } = formikProps

        const fieldKeys = Object.keys(inputFields)

        // const canEditRole = admin && admin.permission.canEditRole()

        // const handleRoleChange = value => {
        //   if (canEditRole) {
        //     setFieldValue('role', value.value)
        //   } else {
        //     toast('You are not allowed to perform this action')
        //   }
        // }

        return (
          <Modal
            title="Profile"
            visible={isOpen}
            onOk={handleSubmit}
            onCancel={handleClose}
            okText='Update'
          > 
            <form>
              <>
                <Card className="mb-5">
                  <Card.Body>
                    {fieldKeys.map(fieldKey => {
                      const field = inputFields[fieldKey]
                      if (field.skip) return null;
                      
                      return(
                        <div className="mb-3" key={fieldKey}>
                          {getInputFromType(field.inputType, fieldKey)}
                        </div>
                      )
                    })}

                    <Form.Label> Role </Form.Label>
                    <div className='mb-3'>
                      <p>
                        {get(ROLES, values.role, '').replaceAll('_', ' ')}
                      </p>
                    </div>
                  </Card.Body>
                </Card>
              </>
            </form>
          </Modal>
        )
      }, inputFields)}
    </Formik>
  );
};

ProfileModal.defaultProps = {
  
}

export default ProfileModal;