import Resource from './resource'

export const ROLES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  ORG_CO_MANAGER: "ORG_CO_MANAGER",
  ORG_RESOURCE_COLLABORATOR_MENTAL_HEALTH: "ORG_RESOURCE_COLLABORATOR_MENTAL_HEALTH",
  ORG_RESOURCE_COLLABORATOR_EVENT: "ORG_RESOURCE_COLLABORATOR_EVENT"
}

export const ORG_SCOPES = {
  ALL_ORGS: "ALL_ORGS"
}

export default class Permission {
  constructor(role, orgScope, ownerProfileId) {
    this.orgScope = orgScope;
    this.role = role
    this.ownerProfileId = ownerProfileId
  }

  canAdminResource(resourceType, orgId = this.orgScope) {
    const allowedResourceTypes = this.getAllowedResourceTypes()
    const canAdminResourceType = allowedResourceTypes.includes(resourceType)
    const canAdminOrg = this.canAdminOrg(orgId)

    return canAdminResourceType && canAdminOrg;
  }

  canApproveResource(orgId = this.orgScope) {
    return this.isSuperAdmin() || (this.isOrgCoManager() && this.canAdminOrg(orgId));
  }

  canEditRole() {
    return this.isSuperAdmin()
  }

  canDeleteResource(resourceType, orgId = this.orgScope) {
    return this.canCreateAndUpdateResource(resourceType, orgId)
  } 
  
  canCreateAndUpdateResources(orgId = this.orgScope) {
    if (this.isSuperAdmin()) {
      return true
    }

    return (
      this.isOrgCoManager() ||
      this.isOrgResourceCollaboratorEvent() ||
      this.isOrgResourceCollaboratorMentalHealth()
      ) && this.canAdminOrg(orgId)
  }
  
  canCreateAndUpdateResource(resourceType, orgId = this.orgScope) {
    if (this.isSuperAdmin()) {
      return true
    }

    if (resourceType === Resource.TYPES.MENTAL_HEALTH) {
      return this.canCreateAndUpdateMentalHealthResources(orgId)
    }

    if (resourceType === Resource.TYPES.EVENT) {
      return this.canCreateAndUpdateEventResources(orgId)
    }

    return this.isOrgCoManager() && this.canAdminOrg(orgId)
  }
  
  canCreateAndUpdateMentalHealthResources(orgId = this.orgScope) {
    return this.isSuperAdmin() || (
      (this.isOrgCoManager() || this.isOrgResourceCollaboratorMentalHealth())
      && this.canAdminOrg(orgId)
    )
  }

  canCreateAndUpdateEventResources(orgId = this.orgScope) {
    return this.isSuperAdmin() || (
      (this.isOrgCoManager() || this.isOrgResourceCollaboratorEvent())
      && this.canAdminOrg(orgId)
    )
  }

  canCreateAndUpdateResourceTags() {
    return this.isSuperAdmin()
  }

  canEditResourceOrg() {
    return this.isSuperAdmin()
  }

  canAdminAllOrgs() {
    return this.orgScope === ORG_SCOPES.ALL_ORGS
  }

  canAdminOrg(orgId) {
    return (this.orgScope === orgId) || (orgId === ORG_SCOPES.ALL_ORGS)
  }

  canEditRoleAndOrgScope(profileId, role) {
    if (!profileId || !role) {
      return true
    }

    if (this.ownerProfileId === profileId) {
      return false
    }

    if (role === ROLES.SUPER_ADMIN) {
      return false
    }

    if (!this.isSuperAdmin()) {
      return false
    }

    return true
  }

  getAllowedResourceTypes() {
    switch(this.role) {
      case ROLES.SUPER_ADMIN:
        return Object.values(Resource.TYPES);
      case ROLES.ORG_CO_MANAGER:
        return [
          Resource.TYPES.EVENT,
          Resource.TYPES.MINDFULNESS,
          Resource.TYPES.FITNESS_LIVE_STREAM,
          Resource.TYPES.FITNESS_ON_CAMPUS,
          Resource.TYPES.FITNESS_ON_DEMAND,
          Resource.TYPES.QUICK_LINK,
          Resource.TYPES.MENTAL_HEALTH
        ]
      case ROLES.ORG_RESOURCE_COLLABORATOR_EVENT:
        return [
          Resource.TYPES.EVENT
        ]
      case ROLES.ORG_RESOURCE_COLLABORATOR_MENTAL_HEALTH:
        return [
          Resource.TYPES.MENTAL_HEALTH
        ]
      default:
        return []
    }
  }

  getRoleName() {
    return this.role.replaceAll('_', ' ')
  }

  getOrgScopeName() {
    return this.orgScope.replaceAll('_', ' ')
  }
  
  isSuperAdmin() {
    return this.role === ROLES.SUPER_ADMIN
  }

  isOrgCoManager() {
    return this.role === ROLES.ORG_CO_MANAGER
  }

  isOrgResourceCollaboratorMentalHealth() {
    return this.role === ROLES.ORG_RESOURCE_COLLABORATOR_MENTAL_HEALTH
  }

  isOrgResourceCollaboratorEvent() {
    return this.role === ROLES.ORG_RESOURCE_COLLABORATOR_EVENT
  }
}
