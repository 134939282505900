import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Clamp from 'components/clamp';

import HtmlHead from 'components/html-head/HtmlHead';
import BreadcrumbList from 'components/breadcrumb-list/BreadcrumbList';
import Pagination from 'components/pagination';
import useCustomLayout from 'hooks/useCustomLayout';
import { MENU_PLACEMENT, LAYOUT } from 'constants.js';
import LoadingWrapper from 'wrappers/loading';
import { getPath, PATH_NAMES } from 'util/urls';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

import { adminsApi } from 'api';

import './index.scss';


const PAGE_SIZE = 7

const Admins = ({ history }) => {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0);
  const [numOfItems, setNumOfItems] = useState(0);

  const title = 'Admins';
  const description = 'An empty page with a fluid vertical layout.';

  const breadcrumbs = [{ to: '/dashboard', text: 'Dashboard' }, { to: '', text: 'Admins' }];

  useCustomLayout({ placement: MENU_PLACEMENT.Vertical, layout: LAYOUT.Fluid });

  useEffect(() => {
    (async () => {
      setLoading(true)

      try {
        const admins = await adminsApi.getAdmins({ page, limit: PAGE_SIZE })

        setAdmins(admins.data.items)
        setNumOfItems(admins.data.total)
      } catch(e) {}

      setLoading(false)
    })()
  }, [page])
  
  const onInviteAdmin = () => {
    history.push(getPath(PATH_NAMES.inviteAdmin).link)
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <Row>
        <Col>
          {/* Title Start */}
          <section className="scroll-section" id="title">
            <div className="page-title-container">
              <Row className="g-0">
                {/* Title Start */}
                <Col className="col-auto mb-3 mb-sm-0 me-auto">
                  <h1 className="mb-0 pb-0 display-4">{title}</h1>
                  <BreadcrumbList items={breadcrumbs} />
                </Col>
                {/* Title End */}

                <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3">
                  <Button onClick={onInviteAdmin} variant="outline-primary" className="btn-icon btn-icon-start w-100 w-md-auto">
                    <CsLineIcons icon="plus" /> <span>Invite Admin</span>
                  </Button>
                </Col>
              </Row>
            </div>
        
            <div className="mb-n2 resources__container">
              <LoadingWrapper loading={loading}>
                {admins.map((admin, idx) => {
                  return (
                    <Card className="mb-2" key={idx}>
                      <Row className="g-0 h-auto sh-sm-18">
                        <Col xs="12" className="col-sm">
                          <Card.Body className="h-100">
                            <NavLink to={getPath(PATH_NAMES.editAdmin, { adminId: admin.id }).link} className="d-flex flex-column mb-lg-0 mb-3 mb-lg-0 pe-3">
                              <div className="lh-1 mb-3 heading">{admin.name}</div>
                              <Clamp className="text-medium text-muted mb-2" clamp="2">
                                Role: {admin.permission.getRoleName()}
                              </Clamp>
                              {!admin.permission.isSuperAdmin() &&
                                <Clamp className="text-medium text-muted mb-2" clamp="2">
                                  Org: {admin.org && admin.org.getEnglishName()}
                                </Clamp>
                              }
                            </NavLink>
                          </Card.Body>
                        </Col>
                      </Row>
                    </Card>
                  )
                })}
              </LoadingWrapper>
            </div>
            <div className='resources__pagination'>
              <Pagination
                currentPage={page + 1}
                onPage={page => setPage(page - 1)}
                total={numOfItems}
                pageSize={PAGE_SIZE}
              />
            </div>
          </section>
          {/* Title End */}
        </Col>
      </Row>
    </>
  );
};

export default Admins;
