import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { resourceTagsApi } from 'api';

import './index.scss';
// await resourceTagsApi.updateResourceTag(resourceTagId, body)

const Row = (props) => {

  const [ currentNavflowWeight, setCurrentNavflowWeight] = useState(props.tag.navflowWeight)
  const [ currentNavflowName, setCurrentNavflowName ] = useState(props.tag.navflowName)
  const [ currentNotes, setCurrentNotes ] = useState(props.tag.notes)
  const [submittable, setSubmittable ] = useState(false)
  const [ editing, setEditing ] = useState(false)


  const startEditing = (tag) => {

    setEditing(true)
    setCurrentNavflowName(tag.navflowName)
    setCurrentNavflowWeight(tag.navflowWeight)
    setCurrentNotes(tag.notes)
  }

  const submitEdit = async(tag) => {
    if (submittable){
      const updated = {
        navflowName: currentNavflowName,
        navflowWeight: currentNavflowWeight,
        notes: currentNotes
      }

      try {
        await resourceTagsApi.updateResourceTag(tag.id, updated)
  
        const newTags = [...props.navflowTags]
        newTags[props.index] = {
          ...tag,
          ...updated
        }
        props.setNavflowTags(newTags)
        toast(`Updated "${tag.title[0].text}"`)
      }
      catch (err){
        toast(`Ran into a problem updating "${tag.title[0].text}": ${err}`)
      }
      exitEditing()
    }
  }

  const exitEditing = () => {
    setEditing(false)
    setCurrentNavflowName(props.tag.navflowName)
    setCurrentNavflowWeight(props.tag.navflowWeight)
    setCurrentNotes(props.tag.notes)
    setSubmittable(false)
  }

  const changeName = (e, oldValue) => {
    if (e.target.value !== oldValue){
      setSubmittable(true)
    }
    setCurrentNavflowName(e.target.value)
  }

  const changeWeight = (e, oldValue) => {
    if (e.target.value !== oldValue){
      setSubmittable(true)
    }

    setCurrentNavflowWeight(e.target.value)
  }

  const changeNotes = (e, oldValue) => {
    if (e.target.value !== oldValue){
      setSubmittable(true)
    }

    setCurrentNotes(e.target.value)
  }

  const tag = props.tag

  if (editing){
    return (
      <tr key = {tag.id}>
        <td>{tag.title[0].text}</td>
        <td>
          <input value={currentNavflowName} onChange={e => changeName(e, tag.navflowName)} />
        </td>
        <td>
          <input value={currentNavflowWeight} onChange={e => changeWeight(e, tag.navflowWeight)} />
        </td>
        <td>
          <textarea value={currentNotes} onChange={e => changeNotes(e, tag.notes)}/>
        </td>
        <td>
          <button className = {`submittable-${submittable} finish-edit`} onClick={e => submitEdit(tag)}>Submit</button>
          <button className = {`submittable-true finish-edit`} onClick={e => exitEditing()}>Cancel</button>
        </td>
      </tr>
    )
  }

  if (!editing){
    return (
      <tr key = {tag.id}>
        <td>{tag.title[0].text}</td>
        <td> {tag.navflowName}</td>
        <td>{tag.navflowWeight}</td>
        <td>{tag.notes}</td>
        <td>
          <button onClick={e => startEditing(tag)}>Edit</button>
        </td>
      </tr>
    )
  }
}

export default Row;