import * as yup from 'yup';

import { FORMIK_INPUT_TYPES } from 'wrappers/formik';

export const FIELDS = { 
  name: {
    name: 'Name',
    default: '',
    validation: yup.string().required(),
    inputType: FORMIK_INPUT_TYPES.TEXT
  },

  email: {
    name: 'Email',
    default: '',
    validation: yup.string().required(),
    inputType: FORMIK_INPUT_TYPES.TEXT
  },

  role: {
    validation: yup.string().required(),
    skip: true
  },
  
  orgScope: {
    validation: yup.string().required(),
    skip: true
  }
}
