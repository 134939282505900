import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

import HtmlHead from 'components/html-head/HtmlHead';
import BreadcrumbList from 'components/breadcrumb-list/BreadcrumbList';
import Pagination from 'components/pagination';
import useCustomLayout from 'hooks/useCustomLayout';
import { MENU_PLACEMENT, LAYOUT } from 'constants.js';
import LoadingWrapper from 'wrappers/loading';
import { getPath, PATH_NAMES } from 'util/urls';

import { orgsApi } from 'api';

import './index.scss';


const PAGE_SIZE = 7

const Organizations = ({ history }) => {
  const [orgs, setOrgs] = useState([]);
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0);
  const [numOfItems, setNumOfItems] = useState(0);

  const title = 'Organizations';
  const description = 'An empty page with a fluid vertical layout.';

  const breadcrumbs = [{ to: '/dashboard', text: 'Dashboard' }, { to: '', text: 'Organizations' }];

  useCustomLayout({ placement: MENU_PLACEMENT.Vertical, layout: LAYOUT.Fluid });

  const emailDomainList = (domains) => {
    let list = ''
    if (Array.isArray(domains)){
      for (const x of domains){
        list += `${x}, `
      }
    }
    list = list.slice(0, list.length-2)
    return (<span>{list}</span>)
  }

  useEffect(() => {
    (async () => {
      setLoading(true)

      try {
        const orgs = await orgsApi.getAll({ page, limit: PAGE_SIZE })

        setOrgs(orgs.data)
        setNumOfItems(orgs.total)
      } catch(e) {}

      setLoading(false)
    })()
  }, [page])

  const pages = numOfItems / PAGE_SIZE

  const onNewResourceTag = () => {
    history.push(getPath(PATH_NAMES.addOrg).link)
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <Row>
        <Col>
          {/* Title Start */}
          <section className="scroll-section" id="title">
            <div className="page-title-container">
              <Row className="g-0">
                {/* Title Start */}
                <Col className="col-auto mb-3 mb-sm-0 me-auto">
                  <h1 className="mb-0 pb-0 display-4">{title}</h1>
                  <BreadcrumbList items={breadcrumbs} />
                </Col>
                {/* Title End */}

                {/* Top Buttons Start */}
                <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3">
                  <Button onClick={onNewResourceTag} variant="outline-primary" className="btn-icon btn-icon-start w-100 w-md-auto">
                    <CsLineIcons icon="plus" /> <span>Create Organization</span>
                  </Button>
                </Col>
                {/* Top Buttons End */}
              </Row>
            </div>
            
            <div className="mb-n2 orgs__container">
              <LoadingWrapper loading={loading}>
                {orgs.map(org => {
                  const orgLink = getPath(PATH_NAMES.editOrg, { orgId: org.id }).link

                  return (
                    <Card className="mb-2" key={org.id}>
                      <Row className="g-0 h-auto sh-sm-18">
                        <Col xs="12" className="col-sm">
                          <Card.Body className="h-100">
                            <NavLink to={orgLink} className="d-flex flex-column mb-lg-0 mb-3 mb-lg-0 pe-3">
                              <div className="lh-1 mb-3 heading">{org.getLocaleName('en-US')}</div>
                              <p className="text-black text mb-2 fs-6">
                                <b> Allowed Email Domains: </b> {emailDomainList(org.allowedEmailDomains)}
                              </p>
                              <p className="text-black text mb-2 fs-6" onClick={e => e.stopPropagation()}>
                                <b>Subdomain:</b><span href = {org.url}> {org.appId}</span>
                              </p>
                              {/* <p className="text-black text mb-2 fs-6" onClick={e => e.stopPropagation()}>
                                <b>Whitelabeled</b> {org.snowflake.logo ? "yes": "no"}
                              </p> */}
                              {/* <Clamp className="text-medium text-muted mb-2" clamp="2">
                                Resource Type: {org.getTypeName()}
                              </Clamp> */}
                            </NavLink>
                          </Card.Body>
                        </Col>
                      </Row>
                    </Card>
                  )
                })}
              </LoadingWrapper>
            </div>

            <div className='orgs__pagination'>
              <Pagination currentPage={page} pages={pages} onPage={setPage} />
            </div>
          </section>
          {/* Title End */}
        </Col>
      </Row>
    </>
  );
};

export default Organizations;
