import { tokensApi } from 'api';
import axios from 'axios';
import Cookies from 'js-cookie';

import { BASE_URL } from "util/config";
import { AUTH_UUID_COOKIE_NAME } from 'util/constants';

class ClientError extends Error {
  data;
}

const client = (() => {
  let hasToken = false;
  let locale = ''

  axios.defaults.baseURL = `${BASE_URL}/api/v1`;

  return {
    defaults: {
      locale,
      addRequestInterceptor(requestInterceptor) {
        axios.interceptors.request.use(
          requestInterceptor,
          error => error);
      },
      addResponseInterceptor(responseHandler) {
        axios.interceptors.response.use(
          responseHandler,
          error => error);
      },
      addErrorInterceptor(errorHandler) {
        axios.interceptors.response.use(
          response => response,
          errorHandler);
      },
      setBaseUrl(url) {
        axios.defaults.baseURL = `${url}/api/v1`;
      },
      setToken(token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        hasToken = !!token;
      },
      setLocale(loc) {
        this.locale = loc
      },
      interceptors: axios.interceptors
    },
    hasToken() {
      return hasToken;
    },
    async get(url, params, options = {}) {
      return axios.get(url, { params, ...options }).catch(e => {
        const err = new ClientError();

        err.message = e.response.data.message;
        err.data = e.response.data;

        throw err;
      })
    },
    async patch(url, body, options = {}) {
      return axios.patch(url, body, options).catch(e => {
        const err = new ClientError();
        
        err.message = e.response.data.message;
        err.data = e.response.data;

        throw err;
      })
    },
    async post(url, body, options = {}) {
      return axios.post(url, body, options).catch(e => {
        const err = new ClientError();
        
        err.message = e.response.data.message;
        err.data = e.response.data;

        throw err;
      })
    },
    async put(url, body, options = {}) {
      return axios.put(url, body, options).catch(e => {
        const err = new ClientError();

        err.message = e.response.data.message;
        err.data = e.response.data;

        throw err;
      })
    },
    async delete(url, body, options = {}) {
      return axios.delete(url, { data: body, ...options }).catch(e => {
        const err = new ClientError();
        
        err.message = e.response.data.message;
        err.data = e.response.data;

        throw err;
      })
    }
  }
})()

export default client;