class BaseModel {
  _extract_locale_text(arr = [], locale = '') {
    if (arr && Array.isArray(arr)) {
      const obj = arr.find(d => d.locale === locale);
      
      if (obj) {
        return obj.text;
      }
    }

    return ''
  }

  _localeObjArrToObj(arr = []) {
    return Object.assign({}, ...arr.map(obj => ({ [obj.locale]: obj })))
  }
}

export default BaseModel;