import { Form } from 'react-bootstrap';
import { Select } from 'antd';
const { Option } = Select;

const LogicField = ({setFieldValue, fieldName, values, tagsObject, disabled, tags, DEFAULT_LOCALE, label, placeholder, index}) => {

	let fieldValues = values[fieldName] || []

	// if (index){
	// 	fieldValues = values[fieldName][index] || []
	// }

	const handleSelect = (tagId) => {
		if (tagsObject[tagId]) {
		  setFieldValue(fieldName, [...fieldValues, tagId])
		}
	  }
	  
	const handleDeselect = (tagId) => {
		setFieldValue(fieldName, [...fieldValues.filter(_tagId => _tagId !== tagId)])
	}

	return (
		<>
			<Form.Label> {label} </Form.Label>
			<Select
				mode="multiple"
				style={{ width: '100%' }}
				placeholder={placeholder}
				onSelect={handleSelect}
				onDeselect={handleDeselect}
				optionLabelProp="label"
				value={fieldValues}
				allowClear
				disabled={disabled}
				className='mb-3'
				filterOption={(input, option) => {
					if (input) {
					return option.label.toLowerCase().includes(input.toLowerCase())
					}
				}}
			>
				{tags.map(tag => {
					return (
						<Option key={tag.id} value={tag.id} label={tag.getLocaleTitle(DEFAULT_LOCALE)}>
							<div className="demo-option-label-item">
								{tag.getLocaleTitle(DEFAULT_LOCALE)}
							</div>
						</Option>
					)
				})}
			</Select>
		</>
	)
}

export default LogicField