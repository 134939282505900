import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Clamp from 'components/clamp';

import HtmlHead from 'components/html-head/HtmlHead';
import BreadcrumbList from 'components/breadcrumb-list/BreadcrumbList';
import Pagination from 'components/pagination';
import useCustomLayout from 'hooks/useCustomLayout';
import { MENU_PLACEMENT, LAYOUT } from 'constants.js';
import LoadingWrapper from 'wrappers/loading';

import { studentsApi } from 'api';

import './index.scss';


const PAGE_SIZE = 7

const Admins = () => {
  const [students, setAdmins] = useState([]);
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0);
  const [numOfItems, setNumOfItems] = useState(0);

  const title = 'Students';
  const description = 'All students.';

  const breadcrumbs = [{ to: '/dashboard', text: 'Dashboard' }, { to: '', text: 'Students' }];

  useCustomLayout({ placement: MENU_PLACEMENT.Vertical, layout: LAYOUT.Fluid });

  useEffect(() => {
    (async () => {
      setLoading(true)

      try {
        const students = await studentsApi.getStudents({ page, limit: PAGE_SIZE })
        setAdmins(students.data)
        setNumOfItems(students.total)
      } catch(e) {}

      setLoading(false)
    })()
  }, [page])

  return (
    <>
      <HtmlHead title={title} description={description} />
      <Row>
        <Col>
          {/* Title Start */}
          <section className="scroll-section" id="title">
            <div className="page-title-container">
              <Row className="g-0">
                {/* Title Start */}
                <Col className="col-auto mb-3 mb-sm-0 me-auto">
                  <h1 className="mb-0 pb-0 display-4">{title}</h1>
                  <BreadcrumbList items={breadcrumbs} />
                </Col>
                {/* Title End */}
              </Row>
            </div>
        
            <div className="mb-n2 resources__container">
              <LoadingWrapper loading={loading}>
                <>
                  <h3>Total Students: {numOfItems}</h3>
                  {students.map((student, idx) => {
                    return (
                      <Card className="mb-2" key={idx}>
                        <Row className="g-0 h-auto sh-sm-18">
                          <Col xs="12" className="col-sm">
                            <Card.Body>
                              <div className="lh-1 mb-3 heading">{student.name}</div>
                              <Clamp className="text-medium text-muted mb-2" clamp="2">
                                <b>Year Of Study:</b>{' '}{' '}{student.yearOfStudy}
                              </Clamp>
                              <Clamp className="text-medium text-muted mb-2" clamp="2">
                                <b>University:</b>{' '}{' '}{student.org.getEnglishName()}
                              </Clamp>
                            </Card.Body>
                          </Col>
                        </Row>
                      </Card>
                    )
                  })}
                </>
              </LoadingWrapper>
            </div>
            <div className='resources__pagination'>
              <Pagination
                currentPage={page + 1}
                onPage={page => setPage(page - 1)}
                total={numOfItems}
                pageSize={PAGE_SIZE}
              />
            </div>
          </section>
          {/* Title End */}
        </Col>
      </Row>
    </>
  );
};

export default Admins;
