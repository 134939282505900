import React, { useContext, useState } from 'react';
// import { Col, Dropdown, Row } from 'react-bootstrap';
import {
  UserOutlined
} from '@ant-design/icons';

import AuthContext from 'contexts/authContext';


import ProfileModal from './ProfileModal';


const NavUserMenuDropdownToggle = React.memo(
  React.forwardRef(({ onClick, expanded = false, user = {} }, ref) => (
    <a
      href="#/!"
      ref={ref}
      className="d-flex user position-relative"
      data-toggle="dropdown"
      aria-expanded={expanded}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(e);
      }}
    >
      <UserOutlined style={{ color: 'white', fontSize: 50, paddingBottom: 20 }} />
      <div className="name">{user.name || '...'}</div>
    </a>
  ))
);


const NavUserMenu = () => {
  const [showProfile, setShowProfile] = useState(false)

  const { profile } = useContext(AuthContext);

  const onClickUser = () => {
    setShowProfile(true)
  }

  const handleProfileClose = () => {
    setShowProfile(false);
  }

  return (
    <div className="user-container d-flex">
      <NavUserMenuDropdownToggle user={profile} onClick={onClickUser} />

      <ProfileModal isOpen={showProfile} onClose={handleProfileClose} adminId={profile && profile.id} />
    </div>
  );
};
export default React.memo(NavUserMenu);
