import React from 'react'
import { Checkbox, Col, DatePicker, Radio, Row, Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Form } from 'react-bootstrap';
import moment from 'moment';
import { DateTime } from "luxon";
import { get } from 'lodash';

import { Wrapper, Section } from './styled'

import { getUTCDate } from './utils'
import { REPEAT_FREQUENCY, WEEKDAY_CONFIGS, YEAR_CONFIGS } from './constants';



const CustomDatePicker = ({ addRecurrence, recurrenceRule, onChange }) => {
  const [state, setState] = React.useState(recurrenceRule.getCleanRule())

  React.useEffect(() => {
    if (recurrenceRule) {
      const ruleObj = recurrenceRule.getCleanRule()

      setState({ ...state, ...ruleObj })
    }
  }, [recurrenceRule, state])

  // Handlers
  const toggleByDay = (e) => {
    const { value } = e.target
    const byweekday = state.byweekday || []
    const index = byweekday.indexOf(value)
    if (index === -1) {
      byweekday.push(value)
      setState({ ...state, byweekday: [...byweekday] })
    } else {
      byweekday.splice(index, 1)
      setState({ ...state, byweekday: [...byweekday] })
    }
  }

  const toggleByMonth = (e) => {
    const { value } = e.target
    const bymonth = state.bymonth || []
    const index = bymonth.findIndex((mon) => mon === +value)
    if (index === -1) {
      bymonth.push(+value)
      setState({ ...state, bymonth: [...bymonth] })
    } else {
      bymonth.splice(index, 1)
      setState({ ...state, bymonth: [...bymonth] })
    }
  }

  React.useEffect(() => {
    const rule = { ...state }
    if (!get(state, 'byweekday', []).length) {
      delete rule.byweekday
    }
    if (!get(state, 'bymonth', []).length) {
      delete rule.bymonth
    }
    if (state.startdate) {
    //   rule.startdate = getUTCDate(state.startdate)
        rule.startdate = state.startdate
        console.log("datetime (5): saved to CustomDatePicker State with custom getUTCDate function: ", state.startdate)
    } else {
      delete rule.startdate
    }

    if (state.interval < 1) {
      delete rule.interval
    }

    if (state.count < 1) {
      delete rule.count
    }

    if (state.until) {
      rule.until = getUTCDate(state.until)
    } else {
      delete rule.until
    }

    onChange(rule)
  }, [onChange, state])


  return (
    <Wrapper>
      <Section>
        <Col>
          <div className='d-flex align-items-center'>
            <Form.Label>
              Start Date{' '}
              <Tooltip placement="right" title="This is the beginning of the event with the start time">
                <QuestionCircleOutlined />
              </Tooltip>
            </Form.Label>
          </div>
          <div>
            <DatePicker
              onChange={
                (momentObject, dateString) => {
                    console.log("DATETIME (1): datestring input is:", dateString)
                    const UTC_timestamp =  DateTime.fromFormat(dateString + " America/Toronto", "yyyy-MM-dd hh:mm:ss z").ts
                    console.log("DATETIME (2): datestring input as ms timestamp:", UTC_timestamp)
                    setState({ ...state, startdate: UTC_timestamp })
                }
            }
              value={state.startdate && moment(state.startdate)}
              showTime
              use12Hours
            />
          </div>
        </Col>
      </Section>

      {addRecurrence &&
        <>
          <Section>
            <Col>
              <Form.Label>
                Until{' '}
                <Tooltip placement="right" title="This is the end of the recurring event">
                  <QuestionCircleOutlined />
                </Tooltip>
              </Form.Label>
              <div>
                <DatePicker
                  onChange={(_, dateString) => {
                    setState({ ...state, until: dateString })
                  }}
                  value={state.until && moment(state.until)}
                />
              </div>
            </Col>
          </Section>
          <Section>
            <Col>
              <Form.Label>Repeat</Form.Label>
              <div>
                <Radio.Group
                  onChange={(e) => setState({ ...state, frequency: e.target.value })}
                  defaultValue={REPEAT_FREQUENCY.YEARLY}
                  value={state.frequency}
                  buttonStyle="solid"
                >
                  <Radio.Button value={REPEAT_FREQUENCY.YEARLY}>Yearly</Radio.Button>
                  <Radio.Button value={REPEAT_FREQUENCY.MONTHLY}>Monthly</Radio.Button>
                  <Radio.Button value={REPEAT_FREQUENCY.WEEKLY}>Weekly</Radio.Button>
                  <Radio.Button value={REPEAT_FREQUENCY.DAILY}>Daily</Radio.Button>
                </Radio.Group>
              </div>
            </Col>
          </Section>

          <Section>
            <Col>
              <Form.Label>Count</Form.Label>
              <Form.Control
                type="number"
                value={state.count}
                name='count'
                max='1000'
                min='1'
                onChange={(e) => setState({ ...state, count: +e.target.value })}
              />
            </Col>
          </Section>

          <Section>
            <Col>
              <Form.Label>Interval</Form.Label>
              <Form.Control
                type='number'
                name='interval'
                min='1'
                value={state.interval}
                onChange={(e) => setState({ ...state, interval: +e.target.value })}
              />
            </Col>
          </Section>

          <Section>
            <Col>
              <Form.Label>On every</Form.Label>
              <Row>
                {WEEKDAY_CONFIGS.map((weekday_config, idx) => {
                  return (
                    <Checkbox
                      key={idx}
                      name={weekday_config.name}
                      value={weekday_config.value}
                      checked={get(state, 'byweekday', []).includes(weekday_config.value)}
                      onChange={toggleByDay}
                    >
                      <span>{weekday_config.title}</span>
                    </Checkbox>
                  )
                })}
              </Row>
            </Col>
          </Section>
          <Section>
            <Col>
              <Form.Label>In</Form.Label>
              <Row>
                {YEAR_CONFIGS.map((year_config, idx) => {
                  return (
                    <Checkbox
                      key={idx}
                      value={year_config.value}
                      checked={get(state, 'bymonth', []).includes(year_config.value)}
                      onChange={toggleByMonth}
                    >
                      <span>{year_config.title}</span>
                    </Checkbox>
                  )
                })}
              </Row>
            </Col>
          </Section>
        </>
      }
    </Wrapper>
  )
}

export default CustomDatePicker
