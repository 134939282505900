import { useEffect, useState } from 'react';
import { Modal, Typography, Input, Divider } from 'antd';


import SelectTypeInput from 'components/select-type-input';


const { Text } = Typography;


const FilterModal = ({ isOpen, onClose, onApply, tags}) => {
  const [typeValue, setTypeValue] = useState([])
  const [currentState, setCurrentState] = useState({})
  const [resourceName, setResourceName] = useState('')

  useEffect(() => {
    setTypeValue(currentState.types || [])
    setResourceName(currentState.q)
  }, [currentState])

  const handleSelect = (value) => {
    setTypeValue([...typeValue, value])
  }

  const handleDeselect = (value) => {
    setTypeValue([...typeValue.filter(t => t !== value)])
  }

  const handleApply = () => {
    const filter = {}

    if (typeValue.length) {
      filter['types'] = typeValue
    }

    if (resourceName) {
        filter.q = resourceName
      }

    if (onApply) {
      setCurrentState(filter)
      onApply(filter)
    }

    handleClose()
  }

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  const handleNameQueryChange = e => {
    setResourceName(e.target.value)
  }

  return (
    <Modal
      title="Resource Tags Filters"
      visible={isOpen}
      onOk={handleApply}
      onCancel={handleClose}
      okText='Apply'
    > 

      <Text className='resources__filter-modal__label'> Search by Name </Text>
      <Input
        onChange={handleNameQueryChange}
        placeholder="Search resource name"
        value={resourceName}
      />

    <Divider plain />

      <Text className='resources__filter-modal__label'> Resource Types </Text>
      <SelectTypeInput
        value={typeValue}
        handleSelect={handleSelect}
        handleDeselect={handleDeselect}
      />
    </Modal>
  );
};

export default FilterModal;
