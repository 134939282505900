import { Suspense, lazy } from 'react';

// import routing modules
import { Redirect, Route, Switch } from 'react-router-dom';
import AuthenticatedRoutes from 'routing/authenticated-routes';
import { getPath, PATH_NAMES } from 'util/urls';

// Style for Ant Design
import "antd/dist/antd.min.css";

const NotFound = lazy(() => import('views/default/NotFound'));
const Login = lazy(() => import('views/default/Login'));
const Unauthorized = lazy(() => import('views/default/Unauthorized'));
// const InvalidAccess = lazy(() => import('views/default/InvalidAccess'));


const App = () => {
  return (
    <Suspense fallback={<div className='loading' />}>
      <Switch>
        <Route path='/dashboard' component={AuthenticatedRoutes} />
        <Route path='/login' exact component={Login} />
        <Redirect exact from="/" to="/login" />
        <Route path={getPath(PATH_NAMES.unauthorized).definition} exact component={Unauthorized} />
        <Route component={NotFound} />
      </Switch>
    </Suspense>
  );
};

export default App;
