import { Spinner } from 'react-bootstrap';


const LoadingWrapper = (props) => {
  if (props.loading) {
    return (
      <Spinner size={props.size} />
    )
  }

  return props.children;
};

LoadingWrapper.defaultProps = {
  size: 'sm'
}

export default LoadingWrapper