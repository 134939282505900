import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';

import Clamp from 'components/clamp';
import CsLineIcons from 'cs-line-icons/CsLineIcons';

import HtmlHead from 'components/html-head/HtmlHead';
import BreadcrumbList from 'components/breadcrumb-list/BreadcrumbList';
import ResourceTypeImg from 'components/resource-type-img';
import Pagination from 'components/pagination';
import useCustomLayout from 'hooks/useCustomLayout';
import { MENU_PLACEMENT, LAYOUT } from 'constants.js';
import LoadingWrapper from 'wrappers/loading';
import { getPath, PATH_NAMES } from 'util/urls';
import AuthContext from 'contexts/authContext';

import { resourcesApi, resourceTagsApi } from 'api';

import FilterModal from './FilterModal';
import { PAGE_SIZE } from './constants';

import './index.scss';


const Resources = ({ history }) => {
  const [resources, setResources] = useState([]);
  const [resourceTags, setResourceTags] = useState([]);
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0);
  const [numOfItems, setNumOfItems] = useState(0);
  const [isFilterOpen, setIsFilterOpen] = useState(false)
  const [filter, setFilter] = useState({})

  const { profile } = useContext(AuthContext)

  const title = 'Resources';
  const description = 'All resources';

  const breadcrumbs = [{ to: '/dashboard', text: 'Dashboard' }, { to: '', text: 'Resources' }];

  useCustomLayout({ placement: MENU_PLACEMENT.Vertical, layout: LAYOUT.Fluid });

  useEffect(() => {
    (async () => {
      setLoading(true)

      const permission = profile.permission;

      if (!filter.types || !filter.types.length) {

        /**
         * Every user that is not a super admin will need to
         * tell the server which resources they are requesting because
         * they are only allowed to fetch specific types of resources.
         */
        filter.types = permission.getAllowedResourceTypes()
      }

      const params = {
        page,
        limit: PAGE_SIZE,
        ...filter,
        orgScope: profile.orgScope
      }

      try {
        const resources = await resourcesApi.getAllResources(params)
        setResources(resources.data)
        setNumOfItems(resources.total)
      } catch(e) {
        toast(e.message)
      }

      setLoading(false)
    })()
  }, [page, filter, profile])


  /**
   * Some users are only allowed to perform actions on some resource types depending
   * on their role, so this hook will add the types to the filter on page start
   */
  useEffect(() => {
    if (profile) {
      setFilter(_filter => {
        return {..._filter, types: profile.permission.getAllowedResourceTypes()}
      })
    }
  }, [profile])


  useEffect(() => {
    (async () => {
      try {
        const resourceTags = await resourceTagsApi.getResourceTags(
          '',
          {
            /**
             * This ensures that the user only sees the tags that belong to the
             * resource types they are allowed to perform an action on.
             */
            types: profile.permission.getAllowedResourceTypes()
          },
          { fetchAll: true }
        )
        setResourceTags(resourceTags.data.tags)
      } catch(e) {}
    })()
  }, [profile])

  const onNewResource = () => {
    history.push(getPath(PATH_NAMES.addResource).link)
  }

  const onFilter = () => {
    setIsFilterOpen(true)
  }

  const handleFilterClose = () => {
    setIsFilterOpen(false)
  }

  const handleFilterApply = (appliedFilter = {}) => {
    setFilter(appliedFilter)
  }


  return (
    <>
      <HtmlHead title={title} description={description} />
      <Row>
        <Col>
          {/* Title Start */}
          <section className="scroll-section" id="title">
            <div className="page-title-container">
              <Row className="g-0">
                {/* Title Start */}
                <Col className="col-auto mb-3 mb-sm-0 me-auto">
                  <h1 className="mb-0 pb-0 display-4">{title}</h1>
                  <BreadcrumbList items={breadcrumbs} />
                </Col>
                {/* Title End */}

                <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3">
                  <Row>
                    <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3">
                      <Button onClick={onFilter} variant="outline-primary" className="btn-icon btn-icon-start w-100 w-md-auto">
                        <CsLineIcons icon="plus" /> <span>Filters</span>
                      </Button>
                    </Col>
                    {profile && profile.permission && profile.permission.canCreateAndUpdateResources() &&
                      <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3">
                        <Button onClick={onNewResource} variant="outline-primary" className="btn-icon btn-icon-start w-100 w-md-auto">
                          <CsLineIcons icon="plus" /> <span>Create Resource</span>
                        </Button>
                      </Col>
                    }
                  </Row>
                </Col>

              </Row>
            </div>

            <div className="mb-n2 resources__container">
              <LoadingWrapper loading={loading}>
                {resources.map(resource => {
                  return (
                    <Card className="mb-4" style={{ height: '170px' }} key={resource.id}>
                      <Row className="g-0 h-auto sh-sm-18">
                        {resource.imageURL &&
                          <Col xs="12" sm="auto" style={{ maxHeight: '170px' }}>
                            <ResourceTypeImg src={resource.imageURL} alt="product" className="card-img card-img-horizontal-sm sh-24 h-sm-100 sw-sm-18" />
                          </Col>
                        }
                        <Col xs="12" className="col-sm">
                          <Card.Body>
                            <NavLink to={getPath(PATH_NAMES.editResource, { resourceId: resource.id }).link} className="d-flex flex-column mb-lg-0 mb-3 mb-lg-0 pe-3">
                              <div className="lh-1 mb-2 heading">{resource.getLocaleTitle('en-US')}</div>
                              <Clamp className="text-medium text-muted mb-2" clamp="2">
                                {resource.getLocaleDetail('en-US')}
                              </Clamp>
                              <div className="text-medium text-muted mb-1">University: {resource.org ? resource.org.getEnglishName() : 'All Orgs'}</div>
                              <div className="text-small text-muted mb-1">{resource.getTypeName()}</div>
                            </NavLink>
                          </Card.Body>
                        </Col>
                      </Row>
                    </Card>
                  )
                })}
              </LoadingWrapper>
            </div>
            <div className='resources__pagination'>
              {/* The Pagination component starts its pagination at 1 and not 0 but the API starts at 0. So we have to account for that */}
              <Pagination
                currentPage={page + 1}
                onPage={page => setPage(page - 1)}
                total={numOfItems}
                pageSize={PAGE_SIZE}
              />
            </div>
          </section>
          {/* Title End */}
        </Col>

        <FilterModal
          isOpen={isFilterOpen}
          onClose={handleFilterClose}
          onApply={handleFilterApply}
          tags={resourceTags}
        />
      </Row>
    </>
  );
};

export default Resources;
