import * as yup from 'yup';

import { FORMIK_INPUT_TYPES } from 'wrappers/formik';

import emergencySupportInfo from './default_emergency_content';

export const FIELDS = {
  appId: {
    name: "Subdomain",
    default: '',
    validation: yup.string().required(),
    inputType: FORMIK_INPUT_TYPES.TEXT
  },
  allowedEmailDomains: {
    name: "Allowed Email Domains (set to * to allow all email domains)",
    default: ["*"],
    validation: yup.array().of(yup.string()).required(),
    inputType: FORMIK_INPUT_TYPES.ARRAY,
  },
  timezone: {
    name: "Timezone (in IANA format)",
    validation: yup.string().required(),
    default: "America/Toronto",
    inputType: FORMIK_INPUT_TYPES.TEXT,
  },
  supportedLanguages: {
    name: "Supported languages",
    default: ["en-US"],
    validation: yup.array().of(yup.string()).required(),
    inputType: FORMIK_INPUT_TYPES.ARRAY,
  },
  logo: {
    name: "Logo URL",
    parent: "snowflake", // engages special nesting logic in form building, check out PR #388 to see these changes
    validation: yup.string().url(),
    inputType: FORMIK_INPUT_TYPES.TEXT,
  },
  name: {
    name: 'Name',
    default: '',
    validation: yup.string().required(),
    localize: true,
    inputType: FORMIK_INPUT_TYPES.TEXT,
    require: true
  },
  url: {
    name: 'Organization Url',
    default: '',
    validation: yup.string().url(),
    inputType: FORMIK_INPUT_TYPES.TEXT,
    require: true
  },
  emergencySupportInfo: {
    name: 'Emergency Support Info',
    localize: true,
    default: emergencySupportInfo,
    validation: yup.string(),
    inputType: FORMIK_INPUT_TYPES.TEXT_AREA
  },
}
