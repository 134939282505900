import { useParams } from 'react-router';
import get from 'lodash/get';


/**
 * This will get paths from the URL and pass them in as props.
 * @param options 
 * @returns 
 */

const withPathId = (options) => (Component) => (props) => {
  const params = useParams();
  const paramsProps = {};
  let pathSlugs = get(options, 'pathSlugs', []);

  if (pathSlugs.length) {
    pathSlugs.forEach(slug => {
      paramsProps[slug] = params[slug];
    })
  }

  return (
    <Component {...props} {...paramsProps}/>
  )
}

export default withPathId;
