import * as yup from 'yup';

import { FORMIK_INPUT_TYPES } from 'wrappers/formik';

export const FIELDS = {
  url: {
    name: 'Link',
    default: '',
    validation: yup.string(),
  },
  title: {
    name: 'Title',
    default: '',
    validation: yup.string(),
    localize: true,
    require: true
  },
  instagram: {
    name: 'Instagram',
    default: '',
    validation: yup.string(),
  },
  facebook: {
    name: 'Facebook',
    default: '',
    validation: yup.string(),
  },
  twitter: {
    name: 'Twitter',
    default: '',
    validation: yup.string(),
  },
  discord: {
    name: 'Discord',
    default: '',
    validation: yup.string(),
  },
  details: {
    name: 'Details',
    default: '',
    validation: yup.string(),
    localize: true,
    inputType: FORMIK_INPUT_TYPES.RICH_TEXT,
    require: true
  },
  location: {
    name: 'Location',
    default: '',
    validation: yup.string(),
    localize: true,
  },
  startTime: {
    name: 'Start Time',
    default: '',
    validation: yup.number(),
    inputType: FORMIK_INPUT_TYPES.DATE_TIME
  },
  endTime: {
    name: 'End Time',
    default: '',
    validation: yup.number(),
    inputType: FORMIK_INPUT_TYPES.DATE_TIME
  },
  imageUrl: {
    name: 'Image Url',
    default: '',
    validation: yup.string(),
  }
}
