// cra imports
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import reportWebVitals from 'reportWebVitals.js';
import { createBrowserHistory } from "history";


// import redux requirements
import { Provider } from 'react-redux';
// import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { store } from 'store.js';

// import html head tags requirements
import { Helmet } from 'react-helmet';
import { REACT_HELMET_PROPS } from 'config.js';

// import multi language
import LangProvider from 'lang/LangProvider';

// import routing modules
import { HashRouter as Router } from 'react-router-dom';

import { AuthContextProvider } from 'contexts/authContext';

import { SENTRY_DSN, SENTRY_SAMPLE_RATE } from './constants';

import App from 'App';

// import toastify for notification
import 'react-toastify/dist/ReactToastify.css';
import { Slide, ToastContainer } from 'react-toastify';
import { ChakraProvider } from "@chakra-ui/react";

// mock server register for demo
// import '@mock-api';

Sentry.init({
  dsn: SENTRY_DSN,
  integrations: [new BrowserTracing()],
  
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: SENTRY_SAMPLE_RATE,
});

const Main = () => {
  // const layoutlessRoutes = useMemo(() => getLayoutlessRoutes({ data: routesAndMenuItems }), []);
  return (
    <ChakraProvider>
      <Provider store={store}>
        {/* <PersistGate loading={null} persistor={persistedStore}> */}
          <Helmet {...REACT_HELMET_PROPS} />
          <ToastContainer transition={Slide} newestOnTop />
          <Router history={createBrowserHistory()} basename='/'>
            <AuthContextProvider>
              <LangProvider>
                <App />
              </LangProvider>
            </AuthContextProvider>
          </Router>
        {/* </PersistGate> */}
      </Provider>
    </ChakraProvider>
  );
};

ReactDOM.render(<Main />, document.getElementById('root'));

/*
 * If you want to start measuring performance in your app, pass a function
 * to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
 */
reportWebVitals();
