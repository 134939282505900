export const PATH_NAMES = {
  admins: 'admins',
  addOrg: 'add-org',
  addResource: 'add-resource',
  addResourceTag: 'add-resource-tag',
  dashboard: 'dashboard',
  editAdmin: 'edit-admin',
  editResource: 'edit-resource',
  editResourceTag: 'edit-resource-tag',
  editOrg: 'edit-org',
  inviteAdmin: 'invite-admin',
  login: 'login',
  navflow: 'navflow',
  notfound: 'not-found',
  orgs: 'orgs',
  resources: 'resources',
  resourceTags: 'resource-tags',
  students: 'students',
  unauthorized: 'unauthorized'
}

export const getPath = (path = '', options = {}) => {
  const {
    admins,
    addOrg,
    addResource,
    addResourceTag,
    dashboard,
    editAdmin,
    editOrg,
    editResource,
    editResourceTag,
    inviteAdmin,
    login,
    navflow,
    notfound,
    orgs,
    resources,
    resourceTags,
    students,
    unauthorized
  } = PATH_NAMES

  switch(path) {
    case admins:
      return {
        link: `/dashboard/admins`,
        definition: `/dashboard/admins`
      }
    case addOrg:
      return {
        link: `/dashboard/orgs/add`,
        definition: `/dashboard/orgs/add`
      }
    case addResource:
      return {
        link: `/dashboard/resources/add`,
        definition: `/dashboard/resources/add`
      }
    case addResourceTag:
      return {
        link: `/dashboard/resourcetags/add`,
        definition: `/dashboard/resourcetags/add`
      }
    case dashboard:
      return {
        link: '/dashboard',
        definition: '/dashboard'
      }
    case inviteAdmin:
      return {
        link: '/dashboard/admins/invite',
        definition: '/dashboard/admins/invite'
      }
    case editAdmin:
      return {
        link: `/dashboard/admins/admin/${options.adminId}`,
        definition: `/dashboard/admins/admin/:adminId`
      }
    case editOrg:
      return {
        link: `/dashboard/orgs/org/${options.orgId}`,
        definition: `/dashboard/orgs/org/:orgId`
      }
    case editResource:
      return {
        link: `/dashboard/resources/resource/${options.resourceId}`,
        definition: `/dashboard/resources/resource/:resourceId`
      }
    case editResourceTag:
      return {
        link: `/dashboard/resourcetags/resourcetag/${options.resourceTagId}`,
        definition: `/dashboard/resourcetags/resourcetag/:resourceTagId`
      }
    case login:
      return {
        link: '/login',
        definition: '/login'
      }
    case notfound:
      return {
        link: '/not-found',
        definition: '/not-found'
      }
    case navflow:
        return {
          link: '/dashboard/navflow',
          definition: '/dashboard/navflow'
        }
    case orgs:
      return {
        link: '/dashboard/orgs',
        definition: '/dashboard/orgs'
      }
    case resources:
      return {
        link: '/dashboard/resources',
        definition: '/dashboard/resources'
      }
    case resourceTags:
      return {
        link: '/dashboard/resourcetags',
        definition: '/dashboard/resourcetags'
      }
    case students:
      return {
        link: '/dashboard/students',
        definition: '/dashboard/students'
      }
    case unauthorized:
      return {
        link: '/unauthorized',
        definition: '/unauthorized'
      }
    default:
      break;
  }
}
