import React, { useEffect, useState } from 'react';
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";

import { AUTH_COOKIE_NAME, AUTH_UUID_COOKIE_NAME } from "util/constants";
import { BASE_URL } from "util/config";
import client from "api/client";
import { tokensApi } from 'api';


const AuthContext = React.createContext({
  setIsAuthorized: () => {},
  setProfile: () => {},
  user: {}
})

export const AuthContextProvider = ({ children }) => {
  const [authState, setAuthState] = useState({ isLoading: true })

  useEffect(() => {
    (async () => {
      let uuid = Cookies.get(AUTH_UUID_COOKIE_NAME);
      let token = Cookies.get(AUTH_COOKIE_NAME);
      try {
        if (uuid && !token) {
          const resp = await tokensApi.getToken(uuid)
          token = resp.data.token;
        }

        if (token) {
          setupClient(token)
          updateState('isAuthorized')(true)
        }
      } catch(e) {}

      updateState('isLoading')(false)
      Cookies.remove(AUTH_UUID_COOKIE_NAME);
    })()
  }, [])

  const logout = () => {
    Cookies.remove(AUTH_COOKIE_NAME)
    updateState('isAuthorized')(false)
    window.location.href = '/#/login';
  }

  useEffect(() => {
    client.defaults.addErrorInterceptor(error => {
      const isUnauthorized = error.response.status === 401;

      if (isUnauthorized && !window.location.href.includes('/login')) {
        logout()
      }

      return Promise.reject(error);
    })
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    let token = Cookies.get(AUTH_COOKIE_NAME);

    if (!token && authState.isAuthorized) {
      updateState('isAuthorized')(false)
    } else if (token && !authState.isAuthorized) {
      updateState('isAuthorized')(true)
    }

  } ,[authState])

  const setupClient = (token) => {
    // TODO: Add call to backend to invalidate token
    // Set token cookie
    Cookies.set(AUTH_COOKIE_NAME, token)
    client.defaults.setToken(token)
  }

  const updateState = key => value => {
    setAuthState(state => {
      state[key] = value;

      return { ...state }
    })
  }

  const authenticate = () => {
    const id = uuidv4();
    Cookies.set(AUTH_UUID_COOKIE_NAME, id);
    window.location.href = `${BASE_URL}/oauth2/auth/login/${id}?continue=${encodeURIComponent(`${window.location.origin}/#/login`)}`
  }

  authState.authenticate = authenticate;
  authState.setProfile = updateState('profile');
  authState.setLoading = updateState('isLoading');
  authState.logout = logout;
  authState.isLoginPage = window.location.href.includes('/login');

  return (
    <>
      <AuthContext.Provider
        value={authState}
      >
        {children}
      </AuthContext.Provider>
    </>
  )
}

export default AuthContext;