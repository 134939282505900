import React, { useState, useEffect } from 'react';
import LoadingWrapper from 'wrappers/loading';
import Row from './Row'

import { resourceTagsApi } from 'api';

const Navflow = () => {

  const [ loading, setLoading ] = useState(false)
  // can maybe change this to a map that gets passed to the row - that way, the row can update any changed row-data
  // on the client as a way to foresee the changes in the server - i.e., upon refreshig the page, it will look the same
  // but for now it's just an array
  const [ navflowTags, setNavflowTags ] = useState([])

  useEffect(() => {
    (async () => {
      setLoading(true)

      try {
        const result = await resourceTagsApi.getAllNavflowTags({})
        console.log(result)
        setNavflowTags(result.items)
      } catch(e) {console.log(e)}

      setLoading(false)
    })()
  }, [])

  return (
    
    <LoadingWrapper loading={loading}>
      <div className = "navflow-dashboard-container">
        <h1> Navflow Dashboard </h1>
        <div className = "table-wrapper">
          <table className = "navflow-dashboard">
            <thead>
              <tr>
                <th>English Name</th>  
                <th>Survey Text</th>  
                <th>Navflow Weight</th>  
                <th>Notes</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              { navflowTags.map((tag, index) => (
                <Row
                  key={tag.id}
                  tag={tag}
                  index={index}
                  navflowTags={navflowTags}
                  setNavflowTags={setNavflowTags}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      </LoadingWrapper>
  )
}

export default Navflow;