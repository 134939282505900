import * as yup from 'yup';

import { FORMIK_INPUT_TYPES } from 'wrappers/formik';

export const FIELDS = {
  title: {
    name: 'Title',
    default: '',
    validation: yup.string().label('Title'),
    localize: true,
    inputType: FORMIK_INPUT_TYPES.TEXT,
    require: true
  }
}
