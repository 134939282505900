import { useContext } from "react";

import AuthContext from "contexts/authContext";

const withAuthentication = () => Component => props => {
  const { logout, isAuthorized, isLoading, isLoginPage } = useContext(AuthContext);

  if (isLoading && !isAuthorized) {
    return (
      <div>Authenticating...</div>
    )
  } else if(!isAuthorized && !isLoginPage) {
    logout()
  } else {
    return <Component {...props}/>
  }

  return null;
}

export default withAuthentication;
