import groupBy from "lodash/groupBy";
import memoize from "lodash/memoize";

import BaseModel from "./base-model";
import ResourceTag from "./resource-tag";

class ResourceTags extends BaseModel {
  constructor(apiTags = [], locale) {
    super()
    this.tags = apiTags.map(apiTag => new ResourceTag(apiTag))
    this.locale = locale;
  }

  getGroupedLocaleTags = locale => {
    return this._getGroupedLocaleTags(locale || this.locale)
  }
  
  _getGroupedLocaleTags = memoize(locale => {
    return groupBy(this.tags, tag => {
      return tag.groupObj[locale].text;
    })
  })

  getTags() {
    return this.tags;
  }

  setLocale(locale) {
    this.locale = locale;
  }
}

export default ResourceTags;