import React, { useContext, useEffect, useState } from 'react';
import { Button, Row, Col, Form, Card, Spinner } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import Select from 'react-select';
import { get } from 'lodash';
import { toast } from 'react-toastify';

import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import Resource from 'models/resource';
import { orgsApi, resourcesApi, resourceTagsApi } from 'api';
import withPathId from 'hoc/withPathId';
import { getPath, PATH_NAMES } from 'util/urls';
import AuthContext from 'contexts/authContext';

import ClubInputs from './ClubInputs';
import ClubResourceInputs from './ClubResourceInputs';
import EmergencySupportInfoInputs from './ESIInputs';
import EventInputs from './EventInputs';
import FitnessLiveStreamInputs from './FitnessLiveStreamInputs';
import FitnessOnCampusInputs from './FitnessOnCampusInputs';
import FitnessOnDemandInputs from './FitnessOnDemandInputs';
import MentalHealthInputs from './MentalHealthInputs';
import MindfulnessInputs from './MindfulnessInputs';
import MOTDInputs from './MOTDInputs';
import QuickLinkInputs from './QuickLinkInputs';

import DeleteModal from './DeleteModal';

import { buildPostBody } from '../../helpers';
import { ORG_SCOPES } from 'models/permission';


const ResourcePage = ({ history, resourceId }) => {
  const title = 'Resource';
  const description = 'Resource Description';

  const [resourceType, setResourceType] = useState('');
  const [resource, setResource] = useState(null)
  const [loading, setLoading] = useState(true)
  const [tags, setTags] = useState([]);
  const [orgs, setOrgs] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updatingResource, setUpdatingResource] = useState(false)

  const { profile } = useContext(AuthContext);

  const isUpdate = !!resourceId;
  

  useEffect(() => {
    (async () => {
        try {
          const resourceTags = await resourceTagsApi.getResourceTags(resourceType, { orgScope: get(profile, 'orgScope') }, { fetchAll: true })
          setTags(resourceTags.data.tags)
      } catch(e) {}
    })()
  }, [resourceType, profile])


  useEffect(() => {
    (async () => {
      setLoading(true)

      if (resourceId) {
        try {
          const result = await resourcesApi.getResource(resourceId)

          setResource(result.data)
          setResourceType(get(result, 'data.type'))
        } catch(e) {}
      }

      setLoading(false)
    })()
  }, [resourceId])

  useEffect(() => {
    (async () => {
      // Only super admins should be able to modify the org
      if (profile.permission && profile.permission.canEditResourceOrg()) {
        try {
          const result = await orgsApi.getAll({}, { fetchAll: true });
          setOrgs(result.data)
        } catch(e) {}
      }
    })()
  }, [profile])


  const onSubmit = async (values, { resetForm }) => {
    const body = buildPostBody(values)
    
    /**
     * If the user is not a super admin, they should not be able
     * to change the org that a resource belongs to.
     */
    if (!profile.permission.canEditResourceOrg() && !body.orgScope) {
      body.orgScope = profile.permission.orgScope
    } else if (!body.orgScope && profile.permission.isSuperAdmin()) {
      body.orgScope = ORG_SCOPES.ALL_ORGS
    } else if (!body.orgScope) {
      body.orgScope = profile.permission.orgScope
    }

    setUpdatingResource(true)

    try {
      if (isUpdate) {
        await resourcesApi.updateResource(resourceId, body)
        history.replace()
        toast('Resource updated')
      } else {
        await resourcesApi.createResource(body)
        resetForm()
        toast('Resource created')
      }
    } catch(e) {
      toast(`Something went wrong: ${e.message}`)
    }

    setUpdatingResource(false)
  }


  if (loading) {
    return (
      <Spinner animation="border" size="sm" variant="primary" className="dzu-spinner-border" />
    )
  }

  const handleResourceChange = option => {
    setResourceType(option.value)
  }

  const handleResourceDelete = async () => {
    setUpdatingResource(true)

    try {
      await resourcesApi.deleteResource(resourceId)
      toast('Resource deleted')
      history.replace(getPath(PATH_NAMES.resources).link)
    } catch(e) {
      toast('Could not delete Resource')
    }

    setUpdatingResource(false)
  }

  const handleOpenDeleteModal = () => {
    setShowDeleteModal(true);
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  }

  const commonProps = { resource, loading, onSubmit, tags, orgs }
  const hasPermissionObject = profile && profile.permission;
  const canDeleteResource = hasPermissionObject && profile.permission.canDeleteResource(resourceType)

  // Allow create and delete when there is no resourceType to validate
  const canCreateAndUpdateResource = !resourceType || (hasPermissionObject && profile.permission.canAdminResource(resourceType))
  
  const resourceTypes = hasPermissionObject ? profile.permission.getAllowedResourceTypes() : []

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className="page-title-container">
        <Row className="g-0">
          {/* Title Start */}
          <Col className="col-auto mb-3 mb-sm-0 me-auto">
            <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to={getPath(PATH_NAMES.resources).link}>
              <CsLineIcons icon="chevron-left" size="13" />
              <span className="align-middle text-small ms-1">Resources</span>
            </NavLink>
            <h1 className="mb-0 pb-0 display-4" id="title">
              {title}
            </h1>
          </Col>
          {!!resourceId && canDeleteResource &&
            <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3">
              <Button onClick={handleOpenDeleteModal} variant="outline-danger" className="btn-icon btn-icon-start w-100 w-md-auto">
                <CsLineIcons icon="bin" /> <span>Delete resource</span>
              </Button>
            </Col>
          }
          {/* Title End */}

        </Row>
      </div>

      <Row>
        <Col xl="12">
        
          <h2 className="small-title">Resource Type</h2>
          <Card className="mb-5">
            <Card.Body>
              <div className="mb-3">
                <Form.Label>Resource Type</Form.Label>
                <Select
                  classNamePrefix="react-select"
                  options={resourceTypes.map(t => {
                    return {
                      value: t,
                      label: get(Resource, `TYPES.${t}`, '').replaceAll('_', ' ') 
                    }})
                  }
                  value={{
                    value: resourceType,
                    label: get(Resource, `TYPES.${resourceType}`, '').replaceAll('_', ' ')
                  }}
                  onChange={handleResourceChange}
                  placeholder="Select here"
                  isDisabled={!canCreateAndUpdateResource}
                />
              </div>
            </Card.Body>
          </Card>

          {resourceType === Resource.TYPES.CLUB &&
            <ClubInputs { ...commonProps }/>
          }
          {resourceType === Resource.TYPES.CLUB_RESOURCE &&
            <ClubResourceInputs { ...commonProps }/>
          }
          {resourceType === Resource.TYPES.EMERGENCY_SUPPORT_INFO &&
            <EmergencySupportInfoInputs { ...commonProps }/>
          }
          {resourceType === Resource.TYPES.EVENT &&
            <EventInputs { ...commonProps }/>
          }
          {resourceType === Resource.TYPES.FITNESS_ON_DEMAND &&
            <FitnessOnDemandInputs { ...commonProps }/>
          }
          {resourceType === Resource.TYPES.FITNESS_ON_CAMPUS &&
            <FitnessOnCampusInputs { ...commonProps }/>
          }
          {resourceType === Resource.TYPES.FITNESS_LIVE_STREAM &&
            <FitnessLiveStreamInputs { ...commonProps }/>
          }
          {resourceType === Resource.TYPES.MENTAL_HEALTH &&
            <MentalHealthInputs { ...commonProps }/>
          }
          {resourceType === Resource.TYPES.MINDFULNESS &&
            <MindfulnessInputs { ...commonProps }/>
          }
          {resourceType === Resource.TYPES.MOTD &&
            <MOTDInputs { ...commonProps }/>
          }
          {resourceType === Resource.TYPES.QUICK_LINK &&
            <QuickLinkInputs { ...commonProps }/>
          }

          {isUpdate &&
            <DeleteModal show={showDeleteModal} handleClose={handleCloseDeleteModal} handleConfirm={handleResourceDelete} loading={updatingResource} />
          }
          
        </Col>
      </Row>
    </>
  );
};

export default withPathId({ pathSlugs:['resourceId'] })(ResourcePage);
