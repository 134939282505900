import { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

// import layout
import Layout from 'layout/Layout';

import Admin from 'views/Admin';
import Admins from 'views/Admins';
import Dashboard from 'views/Dashboard';
import Org from 'views/Org';
import Orgs from 'views/Orgs';
import ResourceTags from 'views/ResourceTags';
import ResourceTag from 'views/ResourceTag';
import Students from 'views/Students';
import Navflow from 'views/Navflow';
import Resources from 'views/Resources'

import AuthContext from 'contexts/authContext';

import { usersApi } from 'api';

import { getPath, PATH_NAMES } from 'util/urls';

import withAuthentication from 'hoc/withAuthentication';
import Resource from 'views/Resource';


const AuthenticatedRoutes = () => {
  const [loadingUser, setLoadingUser] = useState(true);
  const { setProfile, profile } = useContext(AuthContext)

  useEffect(() => {
    (async () => {
      try {
        const user = await usersApi.getProfile()
        setProfile(user)
      } catch(e) {}

      setLoadingUser(false)
    })()
  // eslint-disable-next-line
  }, [])

  if (loadingUser) {
    return (
      <Spinner animation="border" size="sm" variant="primary" className="dzu-spinner-border" />
    )
  }

  return (
    <>
      <Layout>
        <Switch>
          <Route path='/dashboard' exact component={Dashboard} />
          <Route path={getPath(PATH_NAMES.addResource).definition} exact component={Resource} />
          <Route path={getPath(PATH_NAMES.editResource).definition} exact component={Resource} />
          <Route path={getPath(PATH_NAMES.resources).definition} exact component={Resources} />
          {/* <Route path='/dashboard/search' exact component={SearchTester} />
          <Route path='/dashboard/groups' exact component={GroupsPage} />
          <Route path='/dashboard/groups/group/:groupId' exact component={GroupPage} /> */}
          
          {profile && profile.permission && profile.permission.isSuperAdmin() ?
            <>
              <Route path={getPath(PATH_NAMES.resourceTags).definition} exact component={ResourceTags} />
              <Route path={getPath(PATH_NAMES.editResourceTag).definition} exact component={ResourceTag} />
              <Route path={getPath(PATH_NAMES.addResourceTag).definition} exact component={ResourceTag} />
              <Route path={getPath(PATH_NAMES.orgs).definition} exact component={Orgs} />
              <Route path={getPath(PATH_NAMES.addOrg).definition} exact component={Org} />
              <Route path={getPath(PATH_NAMES.editOrg).definition} exact component={Org} />
              <Route path={getPath(PATH_NAMES.admins).definition} exact component={Admins} />
              <Route path={getPath(PATH_NAMES.inviteAdmin).definition} exact component={Admin} />
              <Route path={getPath(PATH_NAMES.editAdmin).definition} exact component={Admin} />
              <Route path={getPath(PATH_NAMES.students).definition} exact component={Students} />
              <Route path={getPath(PATH_NAMES.navflow).definition} exact component={Navflow} />
            </>
            :
            <Redirect to={'/dashboard'} />
          }
        </Switch>

      </Layout>
    </>
  );
};

export default withAuthentication()(AuthenticatedRoutes);
