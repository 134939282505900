import { get, memoize } from "lodash";

import BaseModel from "./base-model";
import RecurringRule from "./recurring-rule"
import Org from "./org";
import { ORG_SCOPES } from "./permission";

export default class Resource extends BaseModel {
  static TYPES = {
    EVENT: 'EVENT',
    QUICK_LINK: 'QUICK_LINK',
    MENTAL_HEALTH: 'MENTAL_HEALTH',
    CLUB: 'CLUB',
    CLUB_RESOURCE: 'CLUB_RESOURCE',
    EMERGENCY_SUPPORT_INFO: 'EMERGENCY_SUPPORT_INFO',
    FITNESS_LIVE_STREAM: 'FITNESS_LIVE_STREAM',
	  FITNESS_ON_DEMAND: 'FITNESS_ON_DEMAND',
	  FITNESS_ON_CAMPUS: 'FITNESS_ON_CAMPUS',
    MINDFULNESS: 'MINDFULNESS',
    MOTD: 'MOTD'
  }

  constructor(apiResource) {
    super()
    this.id = apiResource.id;
    this.approved = apiResource.approved;
    this.url = apiResource.url;
    this.title = apiResource.title;
    this.instagram = apiResource.instagram;
    this.facebook = apiResource.facebook;
    this.twitter = apiResource.twitter;
    this.discord = apiResource.discord;
    this.type = apiResource.type;
    this.tags = apiResource.tags;
    this.onlyForAny = apiResource.onlyForAny;
    this.onlyForAny2 = apiResource.onlyForAny2;
    this.onlyForAny3 = apiResource.onlyForAny3;
    this.onlyForAll = apiResource.onlyForAll;
    this.notForAll = apiResource.notForAll;
    
    this.details = apiResource.details;
    this.location = apiResource.location
    this.startTime = apiResource.startTime;
    this.endTime = apiResource.endTime;
    this.imageURL = apiResource.imageURL;
    this.durationMinutes = apiResource.durationMinutes;
    this.dateRules = apiResource.dateRules
    
    if (!apiResource.orgScope) {
      apiResource.orgScope = { id: ORG_SCOPES.ALL_ORGS }
    }

    this.org = new Org(apiResource.orgScope)
    this.orgScope = apiResource.orgScope;

    if (this.dateRules && this.dateRules.rules) {
      this.dateRules.rules = RecurringRule.fromArray(this.dateRules.rules)
    }
  }

  static fromArray(arrayOfResources = []) {
    return arrayOfResources.map(resource => new Resource(resource))
  }

  getLocaleDetail = memoize(locale => {
    return this._extract_locale_text(get(this, 'details', ''), locale)
  })

  getLocaleLocation = memoize(locale => {
    return this._extract_locale_text(get(this, 'location', ''), locale)
  })

  getLocaleTitle = memoize(locale => {
    return this._extract_locale_text(get(this, 'title', ''), locale)
  })
  
  getTypeName() {
    const type = get(this, 'type', '')
    return type.replaceAll('_', ' ')
  }

  hasDiscord() {
    return !!this.dicord;
  }

  hasFacbook() {
    return !!this.facebook;
  }

  hasInstagram() {
    return !!this.instagram;
  }

  hasTwitter() {
    return !!this.twitter;
  }

  isEvent() {
    return this.type === Resource.TYPES.EVENT;
  }

  isQuickLink() {
    return this.type === Resource.TYPES.QUICK_LINK;
  }

  isMentalHealth() {
    return this.type === Resource.TYPES.MENTAL_HEALTH;
  }

  isClub() {
    return this.type === Resource.TYPES.CLUB;
  }

  isClubResource() {
    return this.type === Resource.TYPES.CLUB_RESOURCE;
  }

  isEmergencySupportInfo() {
    return this.type === Resource.TYPES.EMERGENCY_SUPPORT_INFO;
  }
}