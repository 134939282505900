const emergencySupportInfo =
`<div>
<div class="emergency__item">

  <div class="emergency__desc">
    If you are in need of mental health emergency support, mental health acute intervention, or are
    concerned
    about someone else’s mental health i.e. harming themselves or others, please use the following
    resources:
  </div>

  <br>

</div>

<div class="emergency__item">
  <a href="https://good2talk.ca/ontario/" target="_blank" rel="noopener noreferrer">
    <div class="emergency__title">Good2Talk</div>
  </a>
  <div class="emergency__desc">
    Call&nbsp;
    <a href="tel:+1-866-925-5454" target="_blank" rel="noopener noreferrer">
      <strong>1-866-925-5454</strong>
    </a>&nbsp;
    or text <strong>GOOD2TALKON</strong> to&nbsp;
    <a href="tel:+686868" target="_blank" rel="noopener noreferrer">
      <strong>686868</strong>
    </a>
    <br/>
    <em>
      Good2Talk is a free, confidential service for post-secondary students in Ontario, available
      24/7/365.
      Post-secondary students in Ontario can receive professional counselling and information and
      referrals
      for mental health, addictions and well-being.
    </em>
  </div>
</div>
<div class="emergency__item">
  <a href="https://www.crisisservicescanada.ca/en/" target="_blank" rel="noopener noreferrer">
    &nbsp;
    <div class="emergency__title">Crisis Text Line</div>
  </a>&nbsp;
  <div class="emergency__desc">
    Text <strong>HOME</strong> to&nbsp;
    <a href="tel:+686868" target="_blank" rel="noopener noreferrer">
      <strong>686868</strong>
    </a>&nbsp;
    from anywhere in Canada. <br/>
    <em>
      Crisis Services Canada (CSC) is a national network of existing distress, crisis and suicide
      prevention
      line services. The organization is committed to supporting any person living in Canada who is
      affected
      by suicide, in the most caring and least intrusive manner possible.
    </em>
  </div>
</div>

<div class="emergency__item">
  <div class="emergency__title">Protection Services (on campus)</div>
  <div class="emergency__desc">
    <a href="tel:+613-562-5411" target="_blank" rel="noopener noreferrer">
      <strong>613-562-5411</strong>
    </a>
  </div>
</div>

<div class="emergency__item">
  <div class="emergency__title">Emergency Response (off-campus)</div>
  <div class="emergency__desc">
    <strong>9-1-1</strong>
  </div>
</div>
</div>`

export default emergencySupportInfo