import { groupBy, cloneDeep } from 'lodash';

export const groupInputs = (configs = []) =>  {
  return groupBy(configs, config => {
    if (config.localize) {
      return config.name
    } else {
      return 'Other'
    }
  })
}

export const buildPostBody = (values = {}, original = {}) => {
  const body = {}
  const fields = Object.keys(values)

  fields.forEach(field => {
    const vals = field.split(':')
    const layers = field.split('>')
    const isNested = layers.length === 2
    const hasLocale = vals.length === 2;

    if (hasLocale) {
      const fieldId = vals[0]
      const locale = vals[1]

      if (!body[fieldId]) {
        body[fieldId] = []
      }

      body[fieldId].push({
        locale,
        text: values[field]
      })

    } else if (isNested){
      const parent = layers[0]
      const child = layers[1]

      let existingObject = {}
      if (original && original[parent]) existingObject = original[parent]

      // Make sure that if a nested property is updated, it's siblings aren't wiped
        body[parent] = {
          ...existingObject,
          [child]: values[field]
        }

    } else {
      body[field] = values[field]
    }
  })

  return body;
}

export const buildResourceFields = (fields, resource) => {
  const clonedFields = cloneDeep(fields)
  const fieldIds = Object.keys(clonedFields)
  const join = {}

  fieldIds.forEach(fieldId => {
    const field = clonedFields[fieldId]
    let value = resource[fieldId]

    if (field.localize && Array.isArray(value)) {
      value.forEach(localizedObj => {
        const localizedKey = `${fieldId}:${localizedObj.locale}`
        join[localizedKey] = { default: localizedObj.text }
      })
    } else if (field.parent){ // field is nested
      join[`${field.parent}>${fieldId}`] = { default: cloneDeep(resource[field.parent][fieldId])}
    } else {
      join[fieldId] = { default: value }
    }
  })

  return join;
}

export const buildFields = (fields, locales = [], requiredLocale = 'en-US') => {
  const clonedFields = cloneDeep(fields)
  const fieldIds = Object.keys(clonedFields)
  const join = {}
  const localeMapper = {
    'en-US': 'English',
    'fr-CA': 'French'
  }

  fieldIds.forEach(fieldId => {
    const field = clonedFields[fieldId]

    if (field.localize) {
      locales.forEach(locale => {
        const localizedField = cloneDeep(field)

        // Add the required validation if the require option is set
        if (localizedField.require && locale === requiredLocale && localizedField.validation) {
          localizedField.validation = localizedField.validation.required()
        }

        localizedField.name = `${localizedField.name} (${localeMapper[locale]})`

        // Add a label to enable formik show meaningful errors
        if (localizedField.validation) {
          localizedField.validation = localizedField.validation.label(localizedField.name)
        }

        const localizedKey = `${fieldId}:${locale}`
        join[localizedKey] = localizedField
      })
    } else if (field.parent){ // field is nested
        join[`${field.parent}>${fieldId}`] = cloneDeep(field)
    } else {
      if (field.require && field.validation) {
        field.validation = field.validation.required()
      }

      join[fieldId] = field
    }
  })

  return join;
}

export const mapFieldsToValue = (fields, valueKey) => {
  return Object.assign({}, ...Object.keys(fields).map(fieldKey => {
    const field = fields[fieldKey]
    return {
      [fieldKey]: field[valueKey]
    }
  }))
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}