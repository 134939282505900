export const REPEAT_FREQUENCY = {
  YEARLY: 'YEARLY',
  MONTHLY: 'MONTHLY',
  WEEKLY: 'WEEKLY',
  DAILY: 'DAILY'
}

export const WEEKDAY_CONFIGS = [
  {
    name: 'byMonday',
    value: "MO",
    title: 'Monday'
  },
  {
    name: 'byTuesday',
    value: "TU",
    title: 'Tuesday'
  },
  {
    name: 'byWednesday',
    value: "WE",
    title: 'Wednesday'
  },
  {
    name: 'byThursday',
    value: "TH",
    title: 'Thursday'
  },
  {
    name: 'byFriday',
    value: "FR",
    title: 'Friday'
  },
  {
    name: 'bySaturday',
    value: "SA",
    title: 'Saturday'
  },
  {
    name: 'bySunday',
    value: "SU",
    title: 'Sunday'
  }
];


export const YEAR_CONFIGS = [
  {
    value: 1,
    title: 'January'
  },
  {
    value: 2,
    title: 'February'
  },
  {
    value: 3,
    title: 'March'
  },
  {
    value: 4,
    title: 'April'
  },
  {
    value: 5,
    title: 'May'
  },
  {
    value: 6,
    title: 'June'
  },
  {
    value: 7,
    title: 'July'
  },
  {
    value: 8,
    title: 'August'
  },
  {
    value: 9,
    title: 'September'
  },
  {
    value: 10,
    title: 'October'
  },
  {
    value: 11,
    title: 'November'
  },
  {
    value: 12,
    title: 'December'
  }
]