import React, { useContext, useEffect, useState } from 'react';
import { Row, Col, Button, Form, Card, Spinner } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { get, merge } from 'lodash';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as yup from 'yup';
import { Select as AntDSelect } from 'antd';
import ReactSelect from 'react-select';

import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import Resource from 'models/resource';
import { orgsApi, resourceTagsApi } from 'api';
import withPathId from 'hoc/withPathId';
import formikWrapper from 'wrappers/formik';
import { getPath, PATH_NAMES } from 'util/urls';
import AuthContext from 'contexts/authContext';

import DeleteModal from './DeleteModal';
import { buildResourceFields, buildFields, mapFieldsToValue, buildPostBody } from '../../helpers';
import { FIELDS } from './constants';
import { ORG_SCOPES } from 'models/permission';


const { Option } = AntDSelect;


const SUPPORTED_LOCALES = ['en-US', 'fr-CA']

const ResourceTagPage = ({ history, resourceTagId }) => {
  const title = 'Resource Tag';
  const description = 'Resource Tag Description';

  const [resourceTag, setResourceTag] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updatingResourceTag, setUpdatingResource] = useState(false)
  const [orgs, setOrgs] = useState([]);

  const { profile } = useContext(AuthContext)
  
  const isUpdate = !!resourceTagId;
  let resourceFields = {}

  useEffect(() => {
    (async () => {
      try {
        const result = await orgsApi.getAll({}, { fetchAll: true });
        setOrgs(result.data)
      } catch(e) {}
    })()
  }, [])

  useEffect(() => {
    (async () => {
      setLoading(true)

      if (resourceTagId) {
        try {
          const result = await resourceTagsApi.getResourceTag(resourceTagId)
          setResourceTag(result.data)
        } catch(e) {
          console.log(e)
        }
      }

      setLoading(false)
    })()
  }, [resourceTagId])


  if (resourceTag) {
    resourceFields = buildResourceFields(FIELDS, resourceTag)
  }

  const builtFields = buildFields(FIELDS, SUPPORTED_LOCALES)

  const inputFields = merge({}, builtFields, resourceFields)
  
  if (loading) {
    return (
      <Spinner animation="border" size="sm" variant="primary" className="dzu-spinner-border" />
    )
  }

  const initialValues = mapFieldsToValue(inputFields, 'default')

  const validationSchema = mapFieldsToValue(inputFields, 'validation')

  const onSubmit = async (values, { resetForm }) => {

    console.log("The values are", values)
    console.log("And the other thing is", resetForm)

    const body = buildPostBody(values)

    try {
      if (isUpdate) {
        await resourceTagsApi.updateResourceTag(resourceTagId, body)
        toast('Resource Tag updated')
      } else {
        await resourceTagsApi.createResourceTag(body)
        toast('Resource Tag created')
      }

      resetForm()
    } catch(e) {
      toast(e.message)
    }
  }

  const handleResourceTagDelete = async () => {
    setUpdatingResource(true)

    try {
      await resourceTagsApi.deleteResourceTag(resourceTagId)
      toast('Resource Tag deleted')
      history.replace(getPath(PATH_NAMES.resourceTags).link)
    } catch(e) {
      toast('Could not delete Resource Tag')
    }

    setUpdatingResource(false)
  }

  const handleOpenDeleteModal = () => {
    setShowDeleteModal(true);
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  }

  const canCreateAndUpdateResourceTag = profile.permission.canCreateAndUpdateResourceTags()

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className="page-title-container">
        <Row className="g-0">
          <Col className="col-auto mb-3 mb-sm-0 me-auto">
            <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to={getPath(PATH_NAMES.resourceTags).link}>
              <CsLineIcons icon="chevron-left" size="13" />
              <span className="align-middle text-small ms-1">Resource Tags</span>
            </NavLink>
            <h1 className="mb-0 pb-0 display-4" id="title">
              {title}
            </h1>
          </Col>
          {isUpdate && canCreateAndUpdateResourceTag &&
            <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-end mb-2 mb-sm-0 order-sm-3">
              <Button onClick={handleOpenDeleteModal} variant="outline-danger" className="btn-icon btn-icon-start w-100 w-md-auto">
                <CsLineIcons icon="bin" /> <span>Delete resource tag</span>
              </Button>
            </Col>
          }

        </Row>
      </div>
      <Row>
        <Col xl="12">
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={yup.object().shape(validationSchema)}
          >
            {formikWrapper(({ wrapperProps, formikProps }) => {
              let selectedOption;
              const { getInputFromType } = wrapperProps
              const { handleSubmit, values, setFieldValue, errors} = formikProps

              const fieldKeys = Object.keys(inputFields)

              const handleResourceTypeSelect = (resourceType) => {
                const types = values.resourceTypes || [];

                if (!types.includes(resourceType)) {
                  types.push(resourceType)
                }

                setFieldValue('resourceTypes', [...types])
              }

              if (errors){
                console.log(errors)
              }
            
              const handleResourceTypeDeselect = (resourceType) => {
                const types = values.resourceTypes || [];

                setFieldValue('resourceTypes', [...types.filter(t => t !== resourceType)])
              }

              const allOrgsOption = {
                value: ORG_SCOPES.ALL_ORGS,
                label: 'All orgs'
              }
      
              const handleOrgChange = (option) => {
                let value = ORG_SCOPES.ALL_ORGS;
      
                if (option.value !== ORG_SCOPES.ALL_ORGS) {
                  value = option.value.id;
                }
      
                setFieldValue('orgScope', value)
              }

              if (values.orgScope === ORG_SCOPES.ALL_ORGS) {
                selectedOption = allOrgsOption
              } else {
                const org = orgs.find(org => org.id === values.orgScope)
      
                selectedOption = {
                  value: org,
                  label: org && org.getEnglishName()
                }
              }
              const orgSelectOptions = orgs.map(org => {
                return {
                  value: org,
                  label: org.getEnglishName()
                }})
                orgSelectOptions.push(allOrgsOption)
      

              return (
                <form onSubmit={handleSubmit}>
                  <>
                    <Card className="mb-5">
                      <Card.Body>
                        <div className='d-flex flex-column mb-3'>
                          <Form.Label> Select Org </Form.Label>
                          <ReactSelect
                            classNamePrefix="react-select"
                            options={orgSelectOptions}
                            value={selectedOption}
                            onChange={handleOrgChange}
                            placeholder="Select here"
                          />
                          <Form.Text className='text-danger'>
                            {errors['orgScope']}
                          </Form.Text>
                        </div>
                        <div className="mb-3">
                          <Form.Label>Resource Type</Form.Label>
                          <AntDSelect
                            mode="multiple"
                            style={{ width: '100%' }}
                            placeholder="select a resource type"
                            onDeselect={handleResourceTypeDeselect}
                            onSelect={handleResourceTypeSelect}
                            optionLabelProp="label"
                            value={values.resourceTypes || []}
                            allowClear
                          >
                            {Object.keys(Resource.TYPES).map((resourceType, idx) => {
                              return (
                                <Option key={idx} value={resourceType} label={resourceType}>
                                  <div>
                                    {get(Resource, `TYPES.${resourceType}`, '').replaceAll('_', ' ')}
                                  </div>
                                </Option>
                              )
                            })}
                          </AntDSelect>
                          <Form.Text className='text-danger'>
                            {errors['resourceTypes']}
                          </Form.Text>
                        </div>
                        {fieldKeys.map(fieldKey => {
                          const field = inputFields[fieldKey]
                          if (field.skipRender) return null;
                          
                          return(
                            <div className="mb-3" key={fieldKey}>
                              {getInputFromType(field.inputType, fieldKey)}
                            </div>
                          )
                        })}
                      </Card.Body>
                    </Card>
                    {canCreateAndUpdateResourceTag &&
                      <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-center mb-2 mb-sm-0 order-sm-3">
                        <Button variant="outline-primary" className="btn-icon btn-icon-start w-100 w-md-auto" type='submit'>
                          <CsLineIcons icon="save" /> {isUpdate ? <span>Update</span> : <span>Create</span>}
                        </Button>
                      </Col>
                    }
                  </>
                </form>
              )
            }, inputFields)}
          </Formik>
        </Col>
      </Row>
      {isUpdate && canCreateAndUpdateResourceTag &&
        <DeleteModal show={showDeleteModal} handleClose={handleCloseDeleteModal} handleConfirm={handleResourceTagDelete} loading={updatingResourceTag} />
      }
    </>
  );
};

export default withPathId({ pathSlugs:['resourceTagId'] })(ResourceTagPage);
