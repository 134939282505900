import * as yup from 'yup';

import { FORMIK_INPUT_TYPES } from 'wrappers/formik';


export const FIELDS = {
  url: {
    name: 'Link',
    default: '',
    validation: yup.string(),
  },
  title: {
    name: 'Title',
    default: '',
    validation: yup.string(),
    localize: true,
    require: true
  },
  details: {
    name: 'Details',
    default: '',
    validation: yup.string(),
    localize: true,
    inputType: FORMIK_INPUT_TYPES.RICH_TEXT,
    require: true
  },
  location: {
    name: 'Location',
    default: '',
    validation: yup.string(),
    localize: true,
  }
}
