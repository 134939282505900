import { Button, Modal } from 'react-bootstrap';

const DeleteModal = ({ show, handleClose, handleConfirm, loading }) => {

  return (
    <Modal
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Delete Resource</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Are you sure you want to delete this resource
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={handleClose} disabled={loading}>
          No
        </Button>
        <Button
          onClick={!loading ? handleConfirm : null}
          variant="danger"
          disabled={loading}
        >
          { loading ? 'Loading...' : 'Yes' }
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModal;
