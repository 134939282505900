import * as yup from 'yup';


export const FIELDS = {
  tags: {
    name: 'Tags',
    validation: yup.array().of(yup.string()),
    skipRender: true
  },
  onlyForAny: {
    name: 'onlyForAny',
    validation: yup.array().of(yup.string()),
    skipRender: true,
  },
  onlyForAny2: {
    name: 'onlyForAny2',
    validation: yup.array().of(yup.string()),
    skipRender: true,
  },
  onlyForAny3: {
    name: 'onlyForAny3',
    validation: yup.array().of(yup.string()),
    skipRender: true,
  },
  onlyForAll: {
    name: 'onlyForAll',
    validation: yup.array().of(yup.string()),
    skipRender: true,
  },
  notForAll: {
    name: 'notForAll',
    validation: yup.array().of(yup.string()),
    skipRender: true,
  },
  approved: {
    name: 'Approved',
    validation: yup.bool(),
    skipRender: true
  }
}
