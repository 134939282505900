import Resource from 'models/resource';

import { FIELDS } from './constants';

import BaseInputs from '../BaseInputs';


const FitnessOnCampusInputs = (props) => {
  return (
    <BaseInputs
      {...props}
      type={Resource.TYPES.FITNESS_ON_CAMPUS}
      fields={FIELDS}
    />
  )
}

export default FitnessOnCampusInputs