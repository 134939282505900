import React, { useContext, useEffect, useState } from 'react';
import { Form, Row, Col, Button, Card, Spinner } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Formik } from 'formik';
import * as yup from 'yup';
import { get, merge, omit } from 'lodash';
import Select from 'react-select';

import HtmlHead from 'components/html-head/HtmlHead';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import { adminsApi, orgsApi } from 'api';
import withPathId from 'hoc/withPathId';
import formikWrapper from 'wrappers/formik';
import { getPath, PATH_NAMES } from 'util/urls';
import AuthContext from 'contexts/authContext';


import { buildResourceFields, mapFieldsToValue, buildPostBody, buildFields } from '../../helpers';
import { FIELDS } from './constants';
import { ORG_SCOPES, ROLES } from 'models/permission';


const AdminPage = ({ adminId }) => {
  const title = 'Admin';
  const description = 'Admin Description';
  let adminFields

  const [admin, setAdmin] = useState(null)
  const [loading, setLoading] = useState(true)
  const [orgs, setOrgs] = useState([])

  const { profile } = useContext(AuthContext);

  const isUpdate = !!adminId

  useEffect(() => {
    (async () => {
      setLoading(true)

      if (adminId) {
        try {
          const result = await adminsApi.getAdmin(adminId)
          const _admin = result.data;
          setAdmin(_admin)
        } catch(e) {
          console.log(e)
        }
      }

      setLoading(false)
    })()
  }, [adminId])

  useEffect(() => {
    (async() => {
      try {
        const orgs = await orgsApi.getAll({}, { fetchAll: true })
        setOrgs(orgs.data || [])
      } catch(e) {}
    })()
  }, [])

  let _fields = { ...FIELDS };
  
  if (loading) {
    return (
      <Spinner animation="border" size="sm" variant="primary" className="dzu-spinner-border" />
    )
  }

  if (admin) {
    adminFields = buildResourceFields(_fields, admin)
  }

  const builtFields = buildFields(_fields)

  const inputFields = merge({}, builtFields, adminFields)

  const initialValues = mapFieldsToValue(inputFields, 'default')

  const validationSchema = mapFieldsToValue(inputFields, 'validation')

  const onSubmit = async (values, { resetForm }) => {
    const body = buildPostBody(values)

    if (body.role !== ROLES.SUPER_ADMIN && body.orgScope === ORG_SCOPES.ALL_ORGS) {
      toast('Only User\'s with a SUPER ADMIN role can be assigned to All Orgs')

      return;
    }

    try {
      if (isUpdate) {
        await adminsApi.updateAdmin(adminId, body)
        toast('Admin updated')
      } else {
        await adminsApi.inviteAdmin(body)
        toast('Admin invited')
        resetForm()
      }
    } catch(e) {
      console.log(e)
      toast('Something went wrong')
    }
  }

  const getErrorMessage = (message) => {
    return (
      <p className='text-danger'>{message}</p>
    )
  }

  return (
    <>
      <HtmlHead title={title} description={description} />
      <div className="page-title-container">
        <Row className="g-0">
          <Col className="col-auto mb-3 mb-sm-0 me-auto">
            <NavLink className="muted-link pb-1 d-inline-block hidden breadcrumb-back" to={getPath(PATH_NAMES.admins).link}>
              <CsLineIcons icon="chevron-left" size="13" />
              <span className="align-middle text-small ms-1">Admins</span>
            </NavLink>
            <h1 className="mb-0 pb-0 display-4" id="title">
              {title}
            </h1>
          </Col>

        </Row>
      </div>
      <Row>
        <Col xl="12">
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={yup.object().shape(validationSchema)}
          >
            {formikWrapper(({ wrapperProps, formikProps }) => {
              let selectedOrg;
              let roles;
              const { getInputFromType } = wrapperProps
              const { handleSubmit, values, setFieldValue, errors } = formikProps

              const fieldKeys = Object.keys(inputFields)

              const handleRoleChange = value => {
                setFieldValue('role', value.value)
              }

              const handleOrgScopeChange = value => {
                setFieldValue('orgScope', value.value)
              }

              const orgScopeOptions = orgs.map(org => {
                return {
                  value: org.id,
                  label: org.getEnglishName()
                }
              })
              orgScopeOptions.push({
                value: 'ALL_ORGS',
                label: 'All Orgs'
              })

              /**
               * ALL_ORGS is a special orgScope that will not have an org
               * because it is assigned to all orgs
               */
              if (values.orgScope !== 'ALL_ORGS') {
                const found = orgs.find(org => org.id === values.orgScope)

                if (found) {
                  selectedOrg = found.getEnglishName()
                }
              } else {
                selectedOrg = 'All Orgs'
              }

              const orgScopeValue = {
                value: values.orgScope,
                label: selectedOrg
              }

              if (!isUpdate) {
                roles = Object.keys(ROLES)
              } else {
                roles = Object.keys(omit(ROLES, ['SUPER_ADMIN']))
              }

              return (
                <form onSubmit={handleSubmit}>
                  <>
                    <Card className="mb-5">
                      <Card.Body>
                        {fieldKeys.map(fieldKey => {
                          const field = inputFields[fieldKey]
                          if (field.skip) return null;
                          
                          return(
                            <div className="mb-3" key={fieldKey}>
                              {getInputFromType(field.inputType, fieldKey)}
                            </div>
                          )
                        })}

                        <Form.Label> Role </Form.Label>
                        <Select
                          className='mb-3'
                          classNamePrefix="react-select"
                          options={roles.map(t => {
                            return {
                              value: t,
                              label: get(ROLES, t, '').replaceAll('_', ' ') 
                            }})
                          }
                          isDisabled={!profile.permission.canEditRoleAndOrgScope(adminId, values.role)}
                          value={{
                            value: values.role,
                            label: get(ROLES, values.role, '').replaceAll('_', ' ')
                          }}
                          onChange={handleRoleChange}
                          placeholder="Select role here"
                        />
                        {errors && errors.role && getErrorMessage(errors.role)}

                        <Form.Label> Org Scope </Form.Label>
                        <Select
                          classNamePrefix="react-select"
                          options={orgScopeOptions}
                          value={orgScopeValue}
                          onChange={handleOrgScopeChange}
                          isDisabled={!profile.permission.canEditRoleAndOrgScope(adminId, values.role)}
                          placeholder="Select org scope here"
                        />
                        {errors && errors.orgScope && getErrorMessage(errors.orgScope)}
                      </Card.Body>
                    </Card>
                    <Col xs="12" sm="auto" className="d-flex align-items-end justify-content-center mb-2 mb-sm-0 order-sm-3">
                      <Button variant="outline-primary" className="btn-icon btn-icon-start w-100 w-md-auto" type='submit'>
                        { isUpdate ?
                          <><CsLineIcons icon="save" /> <span>Update</span></>
                          :
                          <><CsLineIcons icon="save" /> <span>Invite</span></>
                        }
                      </Button>
                    </Col>
                  </>
                </form>
              )
            }, inputFields)}
          </Formik>
        </Col>
      </Row>
    </>
  );
};

export default withPathId({ pathSlugs:['adminId'] })(AdminPage);
